// IMPORTANTE: Este array esta ordenado de mayor a menor disponibilidad de los estados. Es importante
// respetar este orden (o tenerlo en cuenta si se van a añadir estados) ya que se utiliza en algunas funciones
// para decidir la disponibilidad global de un conjunto de variantes (por color, por producto, etc.) que puedan tener distintos estados.
const STATUS = {
  ADD: 'ADD',
  ADD_TO_CART: 'ADD_TO_CART',
  FORM: 'FORM',
  MIXED: 'MIXED',
  CC_ONLY: 'CC_ONLY',
  RESERVE: 'RESERVE',
  COMING_SOON: 'COMING_SOON',
  NO_SALE: 'NO_SALE',
  ONLY_CENTRES: 'ONLY_CENTRES',
  ONLY_CLICK_AND_EXPRESS: 'ONLY_CLICK_AND_EXPRESS',
  TEMPORARY_SOLD_OUT: 'TEMPORARY_SOLD_OUT',
  TEMPORARY_UNAVAILABLE: 'TEMPORARY_UNAVAILABLE',
  SOLD_OUT: 'SOLD_OUT',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

const STATUS_TRANSLATIONS = {
  ADD: 'product_detail.add_to_cart.anadir_cesta',
  ADD_TO_CART: 'product_detail.add_to_cart.anadir_cesta',
  SOLD_OUT: 'product_detail.add_to_cart.agotado',
  RESERVE: 'product_detail.add_to_cart.reservar',
  NO_SALE: 'product_detail.add_to_cart.proximamente',
  ONLY_CENTRES: 'product_detail.add_to_cart.solo_centros',
  ONLY_CLICK_AND_EXPRESS: 'product_detail.add_to_cart.anadir_cesta',
  CC_ONLY: 'product_detail.add_to_cart.anadir_cesta',
  FORM: 'product_detail.add_to_cart.formulario_compra',
  TEMPORARY_UNAVAILABLE: 'product_detail.add_to_cart.agotado',
  NOT_AVAILABLE: 'product_detail.add_to_cart.agotado',
  MIXED: 'product_detail.add_to_cart.anadir_cesta',
  COMING_SOON: 'product_detail.add_to_cart.proximamente',
  TEMPORARY_SOLD_OUT: 'product_detail.add_to_cart.agotado',
  SOLD_OUT_COMMON: 'product.sold_out',
};

const STATUS_BY_TYPE = {
  AVAILABLE: [
    STATUS.ADD, 
    STATUS.ADD_TO_CART, 
    STATUS.CC_ONLY, 
    STATUS.MIXED, 
    STATUS.RESERVE, 
    STATUS.FORM,
    STATUS.ONLY_CLICK_AND_EXPRESS
  ],
  NOT_AVAILABLE: [
    STATUS.NO_SALE,
    STATUS.TEMPORARY_UNAVAILABLE,
    STATUS.NOT_AVAILABLE,
    STATUS.SOLD_OUT,
    STATUS.ONLY_CENTRES,
    STATUS.COMING_SOON,
    STATUS.TEMPORARY_SOLD_OUT,
  ],
  NOT_STOCK_CHECK: [
    STATUS.TEMPORARY_UNAVAILABLE,
    STATUS.NOT_AVAILABLE,
    STATUS.ONLY_CENTRES,
  ],
};

const STATUS_TO_SHOW_PRICE = {
  SHOW: [
    STATUS.ADD,
    STATUS.ADD_TO_CART,
    STATUS.RESERVE,
    STATUS.CC_ONLY,
    STATUS.FORM,
    STATUS.ONLY_CENTRES,
    STATUS.NO_SALE,
    STATUS.COMING_SOON,
    STATUS.ONLY_CLICK_AND_EXPRESS,
    STATUS.MIXED
  ],
  NOT_SHOW: [STATUS.TEMPORARY_UNAVAILABLE, STATUS.TEMPORARY_SOLD_OUT, STATUS.NOT_AVAILABLE, STATUS.SOLD_OUT],
};

const PROMOTIONS_WITH_STOCK_CALL = ['gift', 'cost', 'coste'];

const isPromoWithStock = (promoType) => {
  return PROMOTIONS_WITH_STOCK_CALL.includes(promoType)
}

const hasAvailableStatus = (status) => {
  return STATUS_BY_TYPE.AVAILABLE.includes(status?.toUpperCase());
};

const shouldCheckStock = (status) => {
  return !STATUS_BY_TYPE.NOT_STOCK_CHECK.includes(status?.toUpperCase());
};

const getStatusLabel = (status) => {
  return status?.toUpperCase() in STATUS_TRANSLATIONS ? STATUS_TRANSLATIONS[status.toUpperCase()] : undefined;
};

const hasOnlyCentresStatus = (status) => {
  return [STATUS.ONLY_CENTRES].includes(status?.toUpperCase());
}

const hasComingSoonStatus = (status) => {
  return [STATUS.COMING_SOON, STATUS.NO_SALE].includes(status?.toUpperCase());
};

const hasToShowPrice = (status) => {
  return STATUS_TO_SHOW_PRICE.SHOW.includes(status?.toUpperCase());
}

const getPriorityStatus = (status_1, status_2) => {
  const status = status_1?.toUpperCase();
  const reference = status_2?.toUpperCase();
  if (!reference || !STATUS[reference]) return status;
  if (!status || !STATUS[status]) return reference;
  const sortedStatusValues = Object.values(STATUS);
  const statusIndex = sortedStatusValues.indexOf(status);
  const referenceIndex = sortedStatusValues.indexOf(reference);
  return statusIndex <= referenceIndex ? status : reference;
};

const getSkusGroupStatus = (statusList) => {
  if (!Array.isArray(statusList)) return STATUS.NOT_AVAILABLE;
  return statusList.reduce((current, prev) => {
    return getPriorityStatus(current, prev);
  }, STATUS.NOT_AVAILABLE);
};

const getProductIdsToCheckStock = (products) => {
  return products
    .map((product) => {
      const hasAvailableSkus =
        Array.isArray(product._my_colors) &&
        product._my_colors.some((color) => shouldCheckStock(color.add_to_cart));
      return hasAvailableSkus && product.id;
    })
    .filter((product) => product);
};

const setProductsStock = (stock, products) => {
  return products.map((product) => {
    const hasPromotionalProduct = productHasPromotionalProducts(stock, product.id);
    product.stock = stock.products[product.id] || {};
    product.redirectToPDP =
      product.stock.redirectToPDP ||
      product._is_collection ||
      product.stock.status?.toUpperCase() === STATUS.FORM ||
      product.stock.status?.toUpperCase() === STATUS.ONLY_CENTRES;
    if (stock.promos && stock.promos[product.id] && !hasPromotionalProduct) {
      product.principal_promo = stock.promos[product.id];
      const updatedVariants = updateVariantSpecificPromos(product, stock.promos[product.id]);
      if (updatedVariants) {
        product._my_colors = updatedVariants;
      }
    }
    if (stock.principalGifs?.[product.id] && !stock.principalGifs[product.id].is_product_promo) {
      product.principal_gif = stock.principalGifs[product.id];
    }
    if (product.stock.previewDate) {
      product.previewDate = product.stock.previewDate;
    }
    return product;
  });
};

const updateVariantSpecificPromos = (product, productStockPromos) => {
  if (!Array.isArray(productStockPromos)) return;
  const variantSpecificPromos = productStockPromos.filter((promo) => Array.isArray(promo.target_variants));
  if (!variantSpecificPromos.length) return;
  const variantsByColor = product._my_colors;
  variantSpecificPromos.forEach((promo) => {
    variantsByColor?.forEach((color) => {
      color.variants?.forEach((variant) => {
        if (
          promo.target_variants.includes(variant.eciReference) &&
          !variant.all_promos?.some((variantPromo) => promo.id === variantPromo.id)) {
          variant.all_promos = variant.all_promos.concat(promo);
        }
      });
    });
  });
  return variantsByColor;
}

const getAllGiftReferencesFromStock = (stock) => {
  const giftReferences = Object.values(stock.promos ?? {})
    .map(promos => {
      const productGifts = promos
        .filter(promo => isPromoWithStock(promo.type))
        .map(promo => promo.target_references ?? [])
        .flat() ?? [];
      return productGifts;
    })
    .flat();
  const uniqueGiftReferences = Array.from(new Set(giftReferences));
  return uniqueGiftReferences;
};

const productHasPromotionalProducts = (stock, productId, availableGifts) => {
  return stock.promos?.[productId]?.some(promo => {
    return isPromoWithStock(promo.type) &&
      availableGifts ?
        promo.target_references?.some(giftReference => availableGifts.includes(giftReference)) :
        promo.target_references?.length
  });
};


const setProductPromosWithStock = (products, productsStock, giftsStock) => {
  const giftReferences = giftsStock?.ADD ?? [];
  return products.map((product) => {
    const hasProductGifts = productHasPromotionalProducts(productsStock, product.id);
    if (hasProductGifts) {
      const productPromos = productsStock.promos[product.id].filter((promo) => {
        if(isPromoWithStock(promo.type)){
          return promo.target_references?.some(giftReference => giftReferences.includes(giftReference));
        } else {
          return true;
        }
      });
      product.principal_promo = productPromos;
      if (productsStock.principalGifs?.[product.id]?.is_product_promo) {
        const principalGif = productPromos.find((promo) => promo.gifs_free_text && promo.products);
        if (principalGif) {
          product.principal_gif = {
            key: 'custom',
            description: '',
            type: 'url_img',
            url: principalGif.gifs_free_text,
            promo_id: principalGif.id,
            is_product_promo: true,
          }
        }
      }
      const updatedVariants = updateVariantSpecificPromos(product, productsStock.promos[product.id]);
      if (updatedVariants) {
        product._my_colors = updatedVariants;
      }
    }
    return product;
  });
};

const getDefaultStockProduct = () => {
  return {
    status: STATUS.ADD,
    home_delivery: true,
    stock: "home_delivery",
    type: "home_delivery",
    redirectToPDP: false,
    stock_d48: false,
    stock_der: false,
    stock_no: false,
    click_and_car: false,
    eci_express: false,
    eci_pick_up: false,
    sts_pick_up: false,
    sts_companies: [],
    mkp_preferred: {},
    click_and_collect: { eci: { active: false }, sts: { active: false } },
  };
};

const getDefaultStockResponse = (ids) => {
  const defaultResponse = {};
  defaultResponse[STATUS.ADD] = ids;
  defaultResponse[STATUS.COMING_SOON] = [];
  defaultResponse.principalGifs = {};
  defaultResponse.products = {};
  defaultResponse.promos = {};
  defaultResponse.shippingDates = { main: { closesArrivalDate: 0 }};
  defaultResponse.shippingDestinations = [];
  ids.forEach((id) => {
    defaultResponse.principalGifs[id] = {};
    defaultResponse.products[id] = getDefaultStockProduct();
    defaultResponse.promos[id] = [];
  });
  return defaultResponse;
};

module.exports = {
  STATUS,
  STATUS_TRANSLATIONS,
  STATUS_BY_TYPE,
  STATUS_TO_SHOW_PRICE,
  PROMOTIONS_WITH_STOCK_CALL,
  hasAvailableStatus,
  shouldCheckStock,
  getStatusLabel,
  hasComingSoonStatus,
  hasOnlyCentresStatus,
  getSkusGroupStatus,
  getPriorityStatus,
  getProductIdsToCheckStock,
  setProductsStock,
  hasToShowPrice,
  getAllGiftReferencesFromStock,
  productHasPromotionalProducts,
  setProductPromosWithStock,
  updateVariantSpecificPromos,
  isPromoWithStock,
  getDefaultStockResponse
};
