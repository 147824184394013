const dismissAfter = 3000;

const Timer = function (callback, delay) {
  let timerId,
    start,
    remaining = delay;

  this.pause = function () {
    window.clearTimeout(timerId);
    timerId = null;
    remaining -= Date.now() - start;
  };

  this.resume = function () {
    if (timerId) {
      return;
    }

    start = Date.now();
    timerId = window.setTimeout(callback, remaining);
  };

  this.resume();
};

function createMessage(id, text, timer) {
  return {
    id,
    text,
    timer,
  };
}

let maxToastId = 0;

const toastMessageState = () => ({
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    ADD_TOAST_MESSAGE(state, data) {
      state.messages.push(data);
    },
    REMOVE_TOAST_MESSAGE(state, id) {
      state.messages = state.messages.filter((m) => m.id !== id);
    },
    PAUSE_TOAST_MESSAGE(state, id) {
      const message = state.messages.find((m) => m.id == id);
      if (!message) return;
      message.timer.pause();
    },
    RESUME_TOAST_MESSAGE(state, id) {
      const message = state.messages.find((m) => m.id == id);
      if (!message) return;
      message.timer.resume();
    },
  },
  actions: {
    addMessage({ commit }, text) {
      const id = ++maxToastId;
      commit(
        'ADD_TOAST_MESSAGE',
        createMessage(id, text, new Timer(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter))
      );
    },
    removeMessage({ commit }, id) {
      commit('REMOVE_TOAST_MESSAGE', id);
    },
    pauseMessage({ commit }, id) {
      commit('PAUSE_TOAST_MESSAGE', id);
    },
    resumeMessage({ commit }, id) {
      commit('RESUME_TOAST_MESSAGE', id);
    },
  },
});

export default toastMessageState;
