const uiState = () => ({
  namespaced: true,
  state: {
    modalSearch: false,
    modalUser: false,
    modalFilter: false,
    modalFilterFacet: false,
    modalFilterFacetTag: false,
    modalShipping: false,
    searchSpeech: false,
    modalMarket: false,
    modalSort: false,
    modalCustomerNotification: { isOpen: false, variant: undefined },
    modalCategories: false,
    modalChatExpert: false,
    modalSizeGuide: { isOpen: false, sizeGuideUrl: undefined },
    modalFilterSearch: false,
    modalMegadrop: false,
  },
  mutations: {
    manageModal(state, payload) {
      state[payload.name] = payload.status || false;
    },
    hideAll(state) {
      state.modalSearch = false;
      state.modalUser = false;
      state.modalFilter = false;
      state.modalFilterFacet = false;
      state.modalFilterFacetTag = false;
      state.modalShipping = false;
      state.searchSpeech = false;
      state.modalMarket = false;
      state.modalSort = false;
      state.modalCustomerNotification = { isOpen: false, variant: undefined };
      state.modalCategories = false;
      state.modalSizeGuide = { isOpen: false, sizeGuideUrl: undefined };
      state.modalFilterSearch = false;
    },
  },
  actions: {
    async showModal({ commit }, payload) {
      await commit('manageModal', payload);
    },
    async hideAllModals({ commit }) {
      await commit('hideAll');
    },
  },
  getters: {
    getModalStatus: (state) => (modalName) => {
      return state[modalName] ?? false;
    },
    getStatusSearch(state) {
      return state.modalSearch;
    },
    getStatusUser(state) {
      return state.modalUser;
    },
    getStatusFilter(state) {
      return state.modalFilter;
    },
    getStatusFilterSearch(state) {
      return state.modalFilterSearch;
    },
    getStatusFilterFacet(state) {
      return state.modalFilterFacet;
    },
    getStatusFilterFacetTag(state) {
      return state.modalFilterFacetTag;
    },
    getStatusShipping(state) {
      return state.modalShipping;
    },
    getStatusSearchSpeech(state) {
      return state.searchSpeech;
    },
    getStatusModalMarket(state) {
      return state.modalMarket;
    },
    getStatusModalSort(state) {
      return state.modalSort;
    },
    getStatusCustomerNotification(state) {
      return state.modalCustomerNotification && state.modalCustomerNotification.isOpen;
    },
    getDataCustomerNotification(state) {
      return state.modalCustomerNotification && state.modalCustomerNotification.variant;
    },
    getStatusModalChatExpert(state) {
      return state.modalChatExpert;
    },
    getStatusModalSizeGuide(state) {
      return state.modalSizeGuide && state.modalSizeGuide.isOpen;
    },
    getDataModalSizeGuide(state) {
      return state.modalSizeGuide && state.modalSizeGuide.sizeGuideUrl;
    },
    getStatusModalMegadrop(state) {
      return state.modalMegadrop;
    }
  },
});

export default uiState;
