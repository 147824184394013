import md5 from 'CommonUtils/md5';

const support = () => ({
  namespaced: true,
  state: {
    chatConfig: {},
    chatSchedule: {
      chatSupportPdpSchedule: {},
      chatSupportFooterEciSchedule: {},
      chatSupportFooterFoodSchedule: {},
    },
    chatSalesforceOpened: false
  },
  mutations: {
    initSupport(state, page_info) {
      state.chatConfig = page_info._chatsupport;

      state.chatSchedule.chatSupportPdpSchedule = page_info['chat-support-pdp_schedule'];
      state.chatSchedule.chatSupportFooterEciSchedule = page_info['chat-footer-eci_schedule'];
      state.chatSchedule.chatSupportFooterFoodSchedule = page_info['chat-support-footer-food_schedule'];
    },
    setChatSalesforceOpened(state, value) {
      state.chatSalesforceOpened = value;
    }
  },
  actions: {
    async initSupport({ commit }, page_info) {
      await commit('initSupport', page_info);
    },
    async toggleChatSalesforceOpened({commit},boolean) {
      await commit('setChatSalesforceOpened', boolean);
    }
  },
  getters: {
    getChatConfig(state) {
      return state.chatConfig;
    },
    getChatData(state, getters, rootState, rootGetters) {
      const zip = rootGetters['cart/getZip'];
      const userId = rootGetters['cart/getUser']?.id;
      return { ...state.chatConfig?.data, zip, user_id: userId };
    },
    getChatDataSalesforce(state) {
      return { ...state.chatConfig.salesforce_chat_config };
    },
    getChatVa(state, getters, rootState, rootGetters) {
      const userId = rootGetters['cart/getUser']?.id;
      const userName = rootGetters['cart/getUser']?.first_name;

      return { ...state?.chatConfig?.va, titulo: userId + '- ', nombreUsuario: userName };
    },
    getRatioUser(state, getters, rootState, rootGetters) {
      const textId = rootGetters['cart/getUser']?.email
        ? rootGetters['cart/getUser'].email
        : rootGetters['page/getSessionId'];

      return (
        md5(textId)
          .split('')
          .map(function (i) {
            return i.charCodeAt(0);
          })
          .reduce(function (a, b) {
            return a + b;
          }, 0) % 100
      );
    },
    getShowChatSupportFooterSalesforce(state, getters, rootState, rootGetters) {
      return (
        state.chatConfig?.salesforce_chat_config?.enabled &&
        rootGetters['page/getLayoutOptions']?.chatsupport?.footer_eci &&
        getShowGenericChat(
          rootGetters['page/getLayoutOptions']?.chatsupport?.footer_eci,
          rootGetters['cart/isLogged'],
          rootGetters['cart/getUser'].email,
          getters.getRatioUser
        )
      );
    },
    getChatSalesforceOpened(state){
      return state.chatSalesforceOpened;
    },
    getShowChatSupportFooterECI(state, getters, rootState, rootGetters) {
      return (
        rootGetters['page/getLayoutOptions']?.chatsupport?.footer_eci &&
        getShowGenericChat(
          rootGetters['page/getLayoutOptions']?.chatsupport?.footer_eci,
          rootGetters['cart/isLogged'],
          rootGetters['cart/getUser'].email,
          getters.getRatioUser
        )
      );
    },
    getShowChatSupportFooterFood(state, getters, rootState, rootGetters) {
      return (
        rootGetters['page/getLayoutOptions']?.chatsupport?.footer_food &&
        getShowGenericChat(
          rootGetters['page/getLayoutOptions']?.chatsupport?.footer_food,
          rootGetters['cart/isLogged'],
          rootGetters['cart/getUser'].email,
          getters.getRatioUser
        )
      );
    },
    getChatSupportPdpSchedule(state) {
      return state.chatSchedule.chatSupportPdpSchedule;
    },
    getChatSupportFooterEciSchedule(state) {
      return state.chatSchedule.chatSupportFooterEciSchedule;
    },
    getChatSupportFooterFoodSchedule(state) {
      return state.chatSchedule.chatSupportFooterFoodSchedule;
    },
  },
});

const getShowGenericChat = (genericChat, isLogged, userEmail, ratioUser) => {
  if (userEmail && genericChat && genericChat.whlst && genericChat.whlst.includes(md5(userEmail))) return true;
  if (!genericChat?.validSchedule) return false;

  const ratio = isLogged ? genericChat.ratio?.logged : genericChat.ratio?.unlogged;

  return genericChat?.validSchedule && ratio >= ratioUser;
};
export default support;
