import API from 'Services/cart';
const mrcaService = new API('/api');

const COLLECTIVE_FIDELITY_POINTS = '01055';
const HOUR_IN_MILISECONDS = 3600000;

const setMrca = () => {
  return mrcaService
    .getMrcaInfo()
    .then((result) => {
      let validPromotions = [];
      const userPromotions = result.promotions;
      const userCollectives = result.collectives;
      let available_points = 0;
      let available_amount_to_change = 0;

      if (userPromotions) {
        userPromotions.filter(function (promotion) {
          if (Array.isArray(promotion.promotions_cards)) {
            promotion.promotions_cards.forEach(function (card) {
              if (card.wallet.fidelity == 1 && card.wallet.currency.identifier == 171) {
                validPromotions.push(card);
              }
            });
          }
        });

        if (validPromotions[0]) {
          const promotionWallet = validPromotions[0].wallet;
          const calculatePoints =
            Math.floor(promotionWallet.amount / promotionWallet.rank_amount) * promotionWallet.rank_amount;
          available_amount_to_change = (promotionWallet.exchange_value / 100) * calculatePoints;
          available_points = promotionWallet.amount;
        }
      }
      
      const fidelity_points = {
        expires: Date.now() + HOUR_IN_MILISECONDS,
        loyalty_program_171: {
          available_points: available_points,
          available_amount_to_change: available_amount_to_change,
        },
        colectives: userCollectives,
      };
      window.localStorage.setItem('mrca', JSON.stringify(fidelity_points));
      return showMrca(fidelity_points);
    })
    .catch((error) => {
      console.log(error);
      if (error && error.length > 1) {
        if (error[1].status === 404) {
          const fidelity_points = {
            expires: Date.now() + HOUR_IN_MILISECONDS,
            loyalty_program_171: {},
            colectives: [],
          };
          window.localStorage.setItem('mrca', JSON.stringify(fidelity_points));
        }
      }

      return;
    });
};

const showMrca = (mrca) => {
  if (!mrca) return null;
  if (!mrca.loyalty_program_171) return null;
  if (!mrca.colectives.includes(COLLECTIVE_FIDELITY_POINTS)) return null;

  return {
    points: mrca.loyalty_program_171.available_points,
    amount: mrca.loyalty_program_171.available_amount_to_change,
    colectives: mrca.colectives,
  };
};

const UtilsFidelityPoints = {
  handlerMrca: () => {
    if (!window) return null;

    const mrcaStorage = JSON.parse(window.localStorage.getItem('mrca'));
    if (!mrcaStorage) return setMrca();

    if (mrcaStorage.expires < Date.now()) return setMrca();

    return showMrca(mrcaStorage);
  },

  deleteMrca: () => {
    window.localStorage.removeItem('mrca');
  },
};

export default UtilsFidelityPoints;
