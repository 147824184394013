// Este módulo refleja los iconos definidos en less/vuestore/icons.less y los nombres de las variables less utilizados
// para invocarlos. Al utilizar estas variables desde el archivo less en el bloque de estilos de un componente vue,
// no se resuelven correctamente las rutas a las imagenes en los entornos desplegados. Para estos casos, se puede utilizar
// este módulo para asignar a una variable el icono mediante el getter getIconCssUrl. Ej. const arrowRightIcon = getIconsCssUrl('_long_arrow_right');
// y en el bloque de estilos incluir imagen con v-bind. Ej. background-image: v-bind('arrowRightIcon');
const icons = () => ({
  namespaced: true,
  state: {
    iconPaths: {
      _logo: 'images/vuestore/_logo.svg',
      _circle_info: 'images/vuestore/_circle_info.svg',
      _logo_eci: 'images/vuestore/_logo_eci_green.svg',
      _plus: 'images/vuestore/_plus.svg',
      _more: 'images/vuestore/_more.svg',
      _less: 'images/vuestore/_less.svg',
      _dropdown_arrow: 'images/vuestore/_dropdown_arrow.svg',
      _next_arrow: 'images/vuestore/_next_arrow.svg',
      _prev_arrow: 'images/vuestore/_prev_arrow.svg',
      _check: 'images/vuestore/_check.svg',
      _uncheck: 'images/vuestore/_uncheck.svg',
      _cross_cursor: 'images/vuestore/_cross_cursor.svg',
      _delivery_truck: 'images/vuestore/_delivery_truck.svg',
      _chip: 'images/vuestore/_chip.svg',
      _home: 'images/vuestore/_home.svg',
      _point: 'images/vuestore/_point.svg',
      _shop: 'images/vuestore/_shop.svg',
      _arrow_left: 'images/vuestore/_arrow_left.svg',
      _arrow_right: 'images/vuestore/_arrow_right.svg',
      _share: 'images/vuestore/_share.svg',
      _confianza: 'images/vuestore/_confianza.svg',
      _mail: 'images/vuestore/_mail.svg',
      _link: 'images/vuestore/_link.svg',
      _facebook: 'images/vuestore/_facebook.svg',
      _instagram: 'images/vuestore/_instagram.svg',
      _pinterest: 'images/vuestore/_pinterest.svg',
      _tiktok: 'images/vuestore/_tiktok.svg',
      _twitter: 'images/vuestore/_twitter.svg',
      _whatsapp: 'images/vuestore/_whatsapp.svg',
      '_whatsapp-white': 'images/vuestore/_whatsapp_white.svg',
      _youtube: 'images/vuestore/_youtube.svg',
      _x_close: 'images/vuestore/_x_close.svg',
      _x_close_sm: 'images/vuestore/_x_close_sm.svg',
      _gallery: 'images/vuestore/_gallery.svg',
      _camera: 'images/vuestore/_camera.svg',
      _search_speach: 'images/vuestore/_search_speach.svg',
      _basket: 'images/vuestore/_basket.svg',
      _basket_active: 'images/vuestore/_basket_active.svg',
      _profile: 'images/vuestore/_profile.svg',
      _profile_active: 'images/vuestore/_profile_active.svg',
      _wishlist: 'images/vuestore/_wishlist.svg',
      _wishlist_filled: 'images/vuestore/_wishlist_filled.svg',
      _search: 'images/vuestore/_search.svg',
      _categories_icon: 'images/vuestore/_categories_icon.svg',
      _delete: 'images/vuestore/_delete.svg',
      _save: 'images/vuestore/_save.svg',
      _eci_plus: 'images/vuestore/_eci_plus.svg',
      _to_home: 'images/vuestore/_to_home.svg',
      _centro: 'images/vuestore/_centro.svg',
      _lugar: 'images/vuestore/_lugar.svg',
      _pen: 'images/vuestore/_pen.svg',
      _checkbox: 'images/vuestore/_checkbox.svg',
      _checkbox_disabled: 'images/vuestore/_checkbox_disabled.svg',
      _checkbox_unchecked: 'images/vuestore/_checkbox_unchecked.svg',
      _checkbox_unchecked_disabled: 'images/vuestore/_checkbox_unchecked_disabled.svg',
      _checkbox_sm: 'images/vuestore/_checkbox_sm.svg',
      _checkbox_sm_checked: 'images/vuestore/_checkbox_sm_checked.svg',
      _checkbox_sm_checked_green: 'images/vuestore/_checkbox_sm_checked_green.svg',
      _pen: 'images/vuestore/_pen.svg',
      _arrow_up: 'images/vuestore/_arrow_up.svg',
      _long_arrow_left: 'images/vuestore/_long_arrow_left.svg',
      _long_arrow_right: 'images/vuestore/_long_arrow_right.svg',
      _icon_error: 'images/vuestore/_icon_error.svg',
      _audio_on: 'images/vuestore/_audio_on.svg',
      _audio_off: 'images/vuestore/_audio_off.svg',
      _logo_eci_gray_img: 'images/vuestore/_logo_eci_gray_img.svg',
      _filter: 'images/vuestore/_filter.svg',
      _credit_card: 'images/vuestore/_credit_card.svg',
      _eci_card_simple: 'images/vuestore/_eci_card_simple.svg',
      _one_click_plus: 'images/vuestore/_one_click_plus.svg',
      _payment_method_amex: 'images/vuestore/_payment_method_amex.svg',
      _payment_method_eci: 'images/vuestore/_payment_method_eci.svg',
      _payment_method_generic: 'images/vuestore/_payment_method_generic.svg',
      _payment_method_paypal: 'images/vuestore/_payment_method_paypal.svg',
      _eci_icon: 'images/vuestore/_eci_icon.svg',
      _gift: 'images/vuestore/_gift.svg',
      _thrash: 'images/vuestore/_thrash.svg',
      _brand_logo_baby: 'images/vuestore/_brand_logo_baby.svg',
      _brand_logo_coconut: 'images/vuestore/_brand_logo_coconut.svg',
      _brand_logo_cotton_juice: 'images/vuestore/_brand_logo_cotton_juice.svg',
      _brand_logo_couchel: 'images/vuestore/_brand_logo_couchel.svg',
      _brand_logo_dustin: 'images/vuestore/_brand_logo_dustin.svg',
      _brand_logo_easy_wear: 'images/vuestore/_brand_logo_easy_wear.svg',
      _brand_logo_emidio_tucci: 'images/vuestore/_brand_logo_emidio_tucci.svg',
      _brand_logo_enfasis: 'images/vuestore/_brand_logo_enfasis.svg',
      _brand_logo_espartenas: 'images/vuestore/_brand_logo_espartenas.svg',
      _brand_logo_green_coast: 'images/vuestore/_brand_logo_green_coast.svg',
      _brand_logo_kids: 'images/vuestore/_brand_logo_kids.svg',
      _brand_logo_latouche_atelier: 'images/vuestore/_brand_logo_latouche_atelier.svg',
      _brand_logo_latouche_comfort: 'images/vuestore/_brand_logo_latouche_comfort.svg',
      _brand_logo_latouche: 'images/vuestore/_brand_logo_latouche.svg',
      _brand_logo_lloyds: 'images/vuestore/_brand_logo_lloyds.svg',
      _brand_logo_lt_comfort: 'images/vuestore/_brand_logo_lt_comfort.svg',
      _brand_logo_lt: 'images/vuestore/_brand_logo_lt.svg',
      _brand_logo_southern_cotton: 'images/vuestore/_brand_logo_southern_cotton.svg',
      _brand_logo_stella_rittwagen: 'images/vuestore/_brand_logo_stella_rittwagen.svg',
      _brand_logo_tintoretto: 'images/vuestore/_brand_logo_tintoretto.svg',
      _brand_logo_woman_party: 'images/vuestore/_brand_logo_woman_party.svg',
      _brand_logo_woman_limited: 'images/vuestore/_brand_logo_woman_limited.svg',
      _brand_logo_woman: 'images/vuestore/_brand_logo_woman.svg',
      _brand_logo_sfera: 'images/vuestore/_brand_logo_sfera.svg',
      _brand_logo_jo_mr_joe: 'images/vuestore/_brand_logo_jo_mr_joe.svg',
      _brand_logo_boomerang: 'images/vuestore/_brand_logo_boomerang.svg',
      _brand_logo_mountainpro: 'images/vuestore/_brand_logo_mountainpro.svg',
      _brand_logo_verhunt: 'images/vuestore/_brand_logo_verhunt.svg',
      _coming_soon_icon: 'images/vuestore/_coming_soon_icon.svg',
      _notify_me: 'images/vuestore/_notify_me.svg',
      _wrench: 'images/vuestore/_wrench.svg',
    }
  },
  mutations: {},
  actions: {},
  getters: {
    getIcon: (state) => (icon) => {
      return state.iconPaths[icon];
    },
    getIconCssUrl: (state, getters, rootGetters) => (iconName) => {
      const assetsBase = rootGetters.page.assets_base;
      const icon = state.iconPaths[iconName];
      return `url('${assetsBase}/stylesheets/${icon}')`;
    },
    getLongArrowRight: (state, geters, rootGetters) => {
      const assetsBase = rootGetters['page/getAssetsBase'];
      return `${assetsBase}/stylesheets/${state.iconPaths._long_arrow_right}`;
    }
  },
});

export default icons;

