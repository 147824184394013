export const FACET_DELIVERY = Object.freeze({
  CLICK_AND_CAR: 'RECOGIDA_EN_EL_DÍA',
  S48: 'delivery_time::Entrega en 48h',
  ECI_EXPRESS: 'ENTREGA_A_PARTIR_DE_2_HORAS'
});

export const CURRENT_STEP = Object.freeze({
  FACET_DELIVERY: 'FACET_DELIVERY',
  SET_ZIP: 'SET_ZIP',
  SET_TIME_SLOTS: 'SET_TIME_SLOTS',
  CLICK_AND_CAR: 'CLICK_AND_CAR'
});

export const DELIVERY_TYPE = Object.freeze({
  DELIVERY: 'DELIVERY',
  COLLECT: 'COLLECT',
});

export const DELIVERY_PARAMS = Object.freeze({
  DELIVERY_KEY: 'deliveryType',
  DELIVERY: 'delivery',
  COLLECT: 'collect',
  DELIVERY_AND_COLLECT: 'deliveryAndCollect'
});

export const DELIVERY_TIME = Object.freeze({
  DELIVERY_KEY: 'delivery_time'
});

