import ServiceAPI from 'Services/ServiceAPI';

class DatalayerService extends ServiceAPI {
  constructor(endpoint) {
    ///api/firefly/vuestore
    super(endpoint);
  }

  async createDataLayerOrderedItem(order_attempt_id, body) {
    const response = await this.post(body, `/cart/${order_attempt_id}/data-layers/ordered-items`);
    return response;
  }

  async updateDataLayerOrderedItem(order_attempt_id,ordered_item_id) {
    const response = await this.patch({}, `/cart/${order_attempt_id}/data-layers/ordered-items/${ordered_item_id}`);
    return response;
  }

  async deleteDataLayerOrderedItem(order_attempt_id,ordered_item_id){
    const response = await this.delete(`/cart/${order_attempt_id}/data-layers/ordered-items/${ordered_item_id}`);
    return response;
  }
}

export default DatalayerService;
