import API from 'Services/API';
import { DELIVERY_PARAMS } from 'CommonUtils/schemas/facetDelivery';
import GRID_TYPES from 'CommonUtils/gridTypes';
import UserAgent from 'CommonUtils/userAgent';
import PLACE_TYPES from 'Utils/placeTypes';
import UtilsURL from 'CommonUtils/url';
import UtilsCookies from 'CommonUtils/cookies';
import UtilsLocalStorage from 'CommonUtils/localStorage.js';

const Service = new API('/api/vuestore');

const setAdditionalParams = (payload, rootGetters) => {
  payload.additionalParams = [];
  const eci_express = rootGetters['cart/getEciExpress'];
  const click_and_car = rootGetters['cart/getClicAndCar'];
  const is_store_mode = rootGetters['page/isStoreMode'];
  const selectedDeliveryTypeOnFilter = rootGetters['filter/getSelectedDeliveryType'];
  const hasEciExpressCentre = eci_express && eci_express.general_centre;
  const hasClickAndCarCentre = click_and_car && click_and_car.general_centre;
  const hasSelectedDeliveryTypeOnFilter =
    selectedDeliveryTypeOnFilter && selectedDeliveryTypeOnFilter !== DELIVERY_PARAMS.DELIVERY_KEY;
  const hasAMV = rootGetters['page/getIsAMV'];

  if (hasAMV) {
    payload.additionalParams['AMV'] = hasAMV;
  }

  if (hasSelectedDeliveryTypeOnFilter) {
    const isOnlyExpress = DELIVERY_PARAMS.DELIVERY === selectedDeliveryTypeOnFilter;
    const isOnlyCollect = DELIVERY_PARAMS.COLLECT === selectedDeliveryTypeOnFilter;
    const isExpressAndCollect = DELIVERY_PARAMS.DELIVERY_AND_COLLECT === selectedDeliveryTypeOnFilter;

    if ((isOnlyExpress || isExpressAndCollect) && hasEciExpressCentre) {
      payload.additionalParams['eciExpressCentreId'] = eci_express.general_centre;
    } else if (isOnlyCollect) {
      payload.additionalParams['collectCenterId'] = click_and_car.general_centre;
    }
    return payload;
  }

  if (hasEciExpressCentre && hasClickAndCarCentre) {
    payload.additionalParams['eciExpressCentreId'] = eci_express.general_centre;
  } else if (hasEciExpressCentre) {
    payload.additionalParams['eciExpressCentreId'] = eci_express.general_centre;
  } else if (hasClickAndCarCentre) {
    payload.additionalParams['collectCenterId'] = click_and_car.general_centre;
  }

  if (is_store_mode) {
    const eciExpressCentreId = rootGetters['page/getCollectCenterId'];
    if (eciExpressCentreId) {
      payload.additionalParams['eciExpressCentreId'] = eciExpressCentreId;
      payload.additionalParams['collectCenterId'] = eciExpressCentreId;
    }
  }
  return payload;
};
/*
import APICart from 'Services/cart';
const CartService = new APICart('/api/vuestore');
const handleClickNCarCenter = async (general_centre) => {
  return await CartService.postClickAndCar({ general_centre })
    .then((res) => {
      return {
        ok: true,
        data: res.click_and_car,
      };
    })
    .catch((err) => {
      return {
        ok: false,
        error: err,
      };
    });
};
*/

const getSlugsByUrl = (url) => {
  const slugs = url.split('/').filter((slug) => {
    return slug && !['?', '&', '#'].some((character) => slug.includes(character));
  });
  return slugs;
};

const setCurrentItemMegadrop = (slugs, item, parent) => {
  if (!slugs?.length || !item) {
    item.current = item?.is_home;
    item.preselected_by_default = item?.is_home;
    return item;
  }

  const isCurrent = item.parts?.length
    ? item.parts.every((part, index) => {
        return slugs[index] && slugs[index] === part;
      })
    : false;

  item.current = isCurrent;
  item.preselected_by_default = isCurrent;

  if (item.current) {
    if (getUrlWithoutParams(parent?.link) === getUrlWithoutParams(item?.link)) {
      item.current = false;
      item.preselected_by_default = false;
    }
  }

  if (item.children?.length) {
    item.children.some((children) => {
      const menu = setCurrentItemMegadrop(slugs, children, item);
      return menu.current;
    });
    const hasCurrentChildren = item.children.some((children) => children.current);
    if (hasCurrentChildren) {
      item.current = hasCurrentChildren;
      item.preselected_by_default = hasCurrentChildren;
    }
  }
  return item;
};

const getUrlWithoutParams = (url) => {
  return UtilsURL.removeAllQueryParams(url);
};

const page = () => ({
  namespaced: true,
  state: {
    base_url: '',
    assets_base: '',
    _internals: {},
    layout_options: {},
    type: '',
    is_designers: false,
    page: undefined,
    logo: 'logo eci',
    is_fashion: false,
    megadrop: [],
    megadropCustom: {},
    context_menu: [],
    home_context_menu: [],
    has_visible_context_menu: false,
    sitebar: {},
    is_food: false,
    is_app: false,
    is_eci_app_focus: false,
    is_new_app: false,
    is_modal: false,
    is_express: false,
    express_type: false,
    express_enabled: false,
    hasProducts: false,
    has_product_list_bar: false,
    pagination: {},
    category_path: '',
    current_category: '',
    zip: '',
    breadcrumb: [],
    slugs: [],
    searchfilter: {},
    has_search_voice: false,
    delivery_facet: null,
    is_pdp: false,
    is_plp: false,
    locale: 'es_ES',
    search_image_enabled: false,
    searched_image: null,
    site_name: '',
    site_links: {},
    footer_logos: {},
    footer_links: {},
    footer_webs: {},
    footer_copyright: {},
    home_domain: '',
    isServer: true,
    isProd: false,
    brandCustomization: {
      enabled: false,
      className: '',
    },
    sessionId: '',
    isAMV: false,
    collectCenterId: '',
    has_parallax_sheet: false,
    has_mounted_parallax_footer_container: false,
    chatbot: {},
    placements: null,
    gdprCookie: '',
    is_home: false,
    giftsWithStock: [],
    categories: [],
    new_search_experience: false,
    search_source: 'elastic',
    algoliaInfo: { enabled: false, product_list_personalization: false, forceQueryFake: false, events: null, query: null },
    vendors: {},
    basicInfo: {},
    isNewPDP: false
  },
  mutations: {
    initPage(state, page_info) {
      state.base_url = page_info._base_url;
      state._internals = page_info._internals;
      state.layout_options = page_info._layout_options;
      state.type = page_info.page_type;
      state.is_designers = page_info._is_designers;
      state.logo = page_info._logo;
      state.is_fashion = page_info._is_fashion;
      state.context_menu = page_info._context_menu;
      state.home_context_menu = page_info._home_context_menu;
      state.has_visible_context_menu = (Array.isArray(page_info._context_menu) && page_info._context_menu.length) ||
        (page_info._is_home && Array.isArray(page_info._home_context_menu) && page_info._home_context_menu.length);
      state.sitebar = page_info.cross_site_bar;
      state.is_food = page_info._is_food;
      state.is_app = page_info._is_app;
      state.is_eci_app_focus = page_info._is_eci_app_focus;
      state.is_new_app = page_info._is_new_app;
      state.is_home = page_info._is_home;
      state.is_modal = page_info._is_modal;
      state.is_express = page_info._is_express;
      state.express_type = page_info._express_type;
      state.express_enabled = page_info._express_enabled; // LIMPIA Se puede eliminar
      state.hasProducts = page_info.products && page_info.products.length; // LIMPIA Se puede eliminar
      state.pagination = page_info.pagination;
      state.category_path = page_info.category_path;
      state.current_category = page_info.current_category;
      state.zip = page_info._zip_code; // LIMPIA Se puede borrar
      state.breadcrumbs = page_info._breadcrumb;
      state.locale = page_info._locale; // LIMPIA Se puede borrar
      state.slugs = page_info?._internals?.req?.moonshine?.slugs || {};
      state.searchfilter = page_info?._internals?.req?.moonshine?.params?.searchfilter || {};
      state.user = page_info._internals?.req?.moonshine?.user_info?.user || {};
      state.has_search_voice = page_info._has_search_voice;
      state.delivery_facet = page_info.delivery_facet;
      state.is_pdp = page_info.is_pdp;
      state.is_plp = page_info.is_plp;
      state.site_name = page_info._store;
      state.site_links = page_info.site_links;
      state.footer_logos = page_info.footer_logos;
      state.footer_links = page_info.footer_links;
      state.footer_webs = page_info.footer_webs;
      state.footer_copyright = page_info.footer_copyright;
      state.home_domain = page_info._home_domain;
      state.isServer = import.meta.env.SSR;
      state.isProd = import.meta.env.PROD;
      state.is_uniforms = page_info.is_uniforms;
      state.school = page_info.schoolData;
      state.chatConfig = page_info._chatsupport; // LIMPIA se puede borrar
      state.brandCustomization = page_info.brand_customization;
      state.isAMV = page_info._amv;
      state._is_shop_mode = page_info._is_shop_mode;
      state.search_image_enabled = page_info?._layout_options?.search_image_enabled;
      state.searched_image = page_info?._searched_image;
      state.new_type_ahead = page_info.new_type_ahead;
      state.verticalName = state._internals?.req?.moonshine?.context?.vertical;
      state.chatbot = page_info.chatbot;
      state.assets_base = page_info._assets_base;
      state.placements = page_info.placements;
      state.categories = page_info.categories;
      state.new_search_experience = page_info.is_new_search_experience;
      state.is_home = page_info._is_home || false;
      state.search_source = page_info.search_source_option;
      state.algoliaInfo = page_info.algolia_info;
      state.vendors = page_info.vendors;
      state.isNewPDP = page_info.showNewPDP;
    },
    setDeliveryFacet(state, delivery_facet) {
      state.delivery_facet = delivery_facet;
    },
    updateDeliveryFacetItem(state, { type, key, index, prop, value }) {
      let deliveryItem = state.delivery_facet[type]?.[key]?.[index]?.[prop];
      if (deliveryItem) {
        deliveryItem = value;
      }
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    setCountProducts(state, count) {
      state.hasProducts = count;
    },
    setHasProductListBar(state, hasBar) {
      state.has_product_list_bar = hasBar;
    },
    setHasParallaxSheet(state, hasParallax) {
      state.has_parallax_sheet = hasParallax;
    },
    setHasMountedParallaxFooterContainer(state, mounted) {
      state.has_mounted_parallax_footer_container = mounted;
    },
    addPlacements(state, {placements, append = true}) {
      state.placements = append ? { ...state.placements, ...placements } : { ...placements};
    },
    setGDPRCookie(state, value) {
      state.gdprCookie = value;
    },
    addGiftsWithStock(state, giftReferences) {
      const availableGiftsSalesReference = giftReferences?.map((ref) => ref?.split(':')?.[0]) ?? [];
      state.giftsWithStock = Array.from(new Set(state.giftsWithStock.concat(availableGiftsSalesReference)));
    },
    removeGiftsWithStock(state, giftReferences) {
      state.giftsWithStock = state.giftsWithStock.filter((giftReference) => !giftReferences.includes(giftReference));
    },
    setMegadrop(state, value) {
      state.megadrop = value;
    },
    setCurrentItemsMegadrop(state, value) {
      const slugs =
        value ?? state.slugs?.hierarchy ?? state._internals?.req?.url ? getSlugsByUrl(state._internals?.req?.url) : '';
      state.megadrop.some((item) => {
        const menu = setCurrentItemMegadrop(slugs, item);
        return menu.current;
      });
    },
    setLayoutOptions(state, layout_options) {
      state.layout_options = layout_options;
    },
    setBasicInfo(state, value) {
      state.basicInfo = value;
    },
    setVisibleContextMenu(state, value) {
      state.has_visible_context_menu = value;
    }
  },
  actions: {
    async initPage({ commit, dispatch, getters }, page_info) {
      await commit('initPage', page_info);
      if (getters.isStoreMode) {
        const general_centre = getters.getCollectCenterId;
        const resp = await handleClickNCarCenterLS(general_centre);
        if (resp.ok) await dispatch('cart/setClickNCar', resp.data, { root: true });
      }
      if (!getters.isServer && !getters.isModal) {
        dispatch('page/getMegadrop', {}, { root: true });
      }
    },
    async setShowLoyaltyPoints({ commit }, hasLoyaltyPoints) {
      await commit('setHasLoyaltyPoints', hasLoyaltyPoints);
    },
    async setDeliveryFacet({ commit }, delivery_facet) {
      await commit('setDeliveryFacet', delivery_facet);
    },
    async updateDeliveryFacetItem({ commit }, { type, key, index, prop, value }) {
      await commit('updateDeliveryFacetItem', { type, key, index, prop, value });
    },
    async setSessionId({ commit }, sessionId) {
      await commit('setSessionId', sessionId);
    },
    async setCountProducts({ commit }, payload) {
      await commit('setCountProducts', payload);
    },
    async setHasProductListBar({ commit }, hasBar) {
      await commit('setHasProductListBar', hasBar);
    },
    async getStock({ rootGetters }, payload) {
      if (rootGetters['cart/getLoadingStatus']) {
        let countChecker = 0;
        return new Promise((resolve, reject) => {
          const stockCheckHandler = setInterval(async () => {
            if (countChecker >= 100) {
              console.error('ERROR GETTING STOCK BECAUSE CART IS DOWN');
              clearInterval(stockCheckHandler);
              return Service.getStock(payload)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            }
            if (rootGetters['cart/getLoadingStatus']) {
              countChecker++;
              return;
            }
            clearInterval(stockCheckHandler);
            payload = setAdditionalParams(payload, rootGetters);
            return Service.getStock(payload)
              .then((response) => resolve(response))
              .catch((error) => reject(error));
          }, 100);
        });
      }
      payload = setAdditionalParams(payload, rootGetters);
      return Service.getStock(payload)
        .then((response) => response)
        .catch((error) => error);
    },
    getProvince(context, payload) {
      return Service.getProvince(payload);
    },
    async getLocationSuggestions(context, payload) {
      return await Service.getLocationSuggestions(payload);
    },
    async getSchoolSuggestions(context, payload) {
      return await Service.getSchoolSuggestions(payload);
    },
    async getSearchProducts(context, payload) {
      return await Service.getSearchProducts(payload);
    },
    async getSearchProductsV2(context, payload) {
      return await Service.getSearchProductsV2(payload);
    },
    async setHasParallaxSheet({ commit }, payload) {
      await commit('setHasParallaxSheet', payload);
    },
    async setHasMountedParallaxFooterContainer({ commit }, payload) {
      await commit('setHasMountedParallaxFooterContainer', payload);
    },
    addPlacements({ commit }, { placements, append}) {
      if (!placements) return;
      commit('addPlacements', { placements, append});
    },
    setGDPRCookie({ commit }, payload) {
      if (!payload) return;
      commit('setGDPRCookie', payload);
    },
    async addGiftsWithStock({ commit }, giftReferences) {
      await commit('addGiftsWithStock', giftReferences);
    },
    async removeGiftsWithStock({ commit }, giftReferences) {
      await commit('removeGiftsWithStock', giftReferences);
    },
    getMegadrop({ commit, rootGetters }, payload) {
      Service.getMegadrop(payload)
        .then((response) => {
          commit('setMegadrop', response._megadrop);
          const isPDP = rootGetters['pdp/getIsPdp'];
          commit('setCurrentItemsMegadrop', isPDP ? rootGetters['pdp/getSlugs'] : null);
        })
        .catch((error) => {
          console.error('Error getting megadrop', error);
        });
    },
    fetchBasicInfo({commit}) {
      const existsCurrentBasicInfo = UtilsLocalStorage.getItem('basicUserInfo');
      const currentBasicInfo = UtilsLocalStorage.getJSONItem('basicUserInfo');
      const now = new Date(Date.now());
      const expires = new Date(currentBasicInfo?.expires);
      const diffTime = expires - now;

      if (!existsCurrentBasicInfo || diffTime < 0) {
        Service.getBasicInfo()
        .then((response) => {
          if (response.headers) {
            delete response.headers;
          }
          UtilsLocalStorage.setJSONItem('basicUserInfo', response);
          commit('setBasicInfo', response);
        })
        .catch((error) => {
          console.error('Error getting basic info', error);
        });
      } else {
        commit('setBasicInfo', currentBasicInfo);
      }
    },
    removeBasicInfo({commit}) {
      const existsCurrentBasicInfo = UtilsLocalStorage.getItem('basicUserInfo');
      if (existsCurrentBasicInfo) {
        UtilsLocalStorage.removeItem('basicUserInfo');
        commit('setBasicInfo', {});
      }
    },
    async setVisibleContextMenu({ commit }, isVisible) {
      await commit('setVisibleContextMenu', isVisible)
    },
  },
  getters: {
    getBaseUrl(state) {
      return state.base_url;
    },
    isExpress(state) {
      return state.is_express;
    },
    expressType(state) {
      return state.express_type;
    },
    isDesigners(state) {
      return state.is_designers;
    },
    isClickAndCar(state) {
      //LIMPIA: se podría borrar
      return state.is_express && state.express_type == '2';
    },
    isFood(state) {
      return state.is_food || false;
    },
    isApp(state) {
      return state.is_app || false;
    },
    isEciAppFocus(state) {
      return state.is_eci_app_focus || false;
    },
    isNewApp(state) {
      return state.is_new_app || false;
    },
    isModal(state) {
      return state.is_modal || false;
    },
    isUniforms(state) {
      return state.is_uniforms || false;
    },
    isExpressEnabled(state) {
      return state.express_enabled;
    },
    hasProducts(state) {
      return state.hasProducts;
    },
    hasProductListBar(state) {
      return state.has_product_list_bar;
    },
    getPagination(state) {
      return state.pagination;
    },
    getZip(state) {
      return state.zip;
    },
    getSearchedImageEnabled(state) {
      return state.search_image_enabled;
    },
    getSearchImage(state) {
      return state.searched_image;
    },
    getSearchImageID(state) {
      const { image_id } = state._internals?.req?.query || {};
      if (!image_id) return;
      return image_id;
    },
    getSchoolData(state) {
      return state.school;
    },
    getActualCategoryPath(state) {
      return state.category_path;
    },
    getCurrentCategory(state) {
      return state.current_category;
    },
    getContextMenu(state) {
      return state.context_menu || state.home_context_menu;
    },
    hasVisibleContextMenu(state) {
      return state.has_visible_context_menu;
    },
    getLogo(state) {
      return state.logo;
    },
    getInternals(state) {
      return state._internals;
    },
    getDeliveryFacet(state) {
      return state.delivery_facet;
    },
    getBreadcrumbs(state) {
      if (!state.breadcrumbs) return [];
      return state.breadcrumbs.ancestors ? state.breadcrumbs.ancestors.concat([state.breadcrumbs.current]) : [];
    },
    isStoreMode(state) {
      return (state.is_app && state._is_shop_mode) || false;
    },
    getCollectCenterId(state) {
      const centerId = import.meta.env.SSR ? false : UtilsCookies.getCookieValue('shopCenter');
      if (!state._is_shop_mode) return false;
      return centerId;
    },
    getGrid(state) {
      let CURRENT_GRID;

      const { is_app, layout_options, _internals } = state;
      const userAgent = _internals?.req?.headers?.['user-agent'];
      const { grid_fashion_mobile, grid_fashion_desktop } = layout_options;

      const hasGridMobile = grid_fashion_mobile && grid_fashion_mobile.type;
      const hasGridDesktop = grid_fashion_desktop && grid_fashion_desktop.type;

      if (!hasGridMobile && !hasGridDesktop) return GRID_TYPES.LITTLE_PRODUCTS;

      const MOBILE_GRID = grid_fashion_mobile.type;
      const DESKTOP_GRID = grid_fashion_desktop.type;

      if (is_app || UserAgent.isMobile(userAgent)) return (CURRENT_GRID = MOBILE_GRID);

      CURRENT_GRID = DESKTOP_GRID;

      return CURRENT_GRID;
    },
    getSlugs(state) {
      return state.slugs;
    },
    getSearchfilter(state) {
      return state.searchfilter;
    },
    getIsSearchVoice(state) {
      return state.has_search_voice;
    },
    getIsPdp(state) {
      return state.is_pdp;
    },
    getIsPlp(state) {
      return state.is_plp;
    },
    getSiteBar(state) {
      return state.sitebar;
    },
    getLocale(state) {
      return state.locale;
    },
    getSiteName(state) {
      return state.site_name.toUpperCase();
    },
    getUser(state) {
      return state.user;
    },
    getSiteLinks(state) {
      return state.site_links;
    },
    getFooterLogos(state) {
      return state.footer_logos;
    },
    getFooterLinks(state) {
      return state.footer_links;
    },
    getFooterWebs(state) {
      return state.footer_webs;
    },
    getFooterCopyright(state) {
      return state.footer_copyright;
    },
    getHomeDomain(state) {
      return state.home_domain;
    },
    isServer(state) {
      return state.isServer;
    },
    isProd(state) {
      return state.isProd;
    },
    getMegadrop(state) {
      return state.megadrop;
    },
    getMegadropCustom(state) {
      return state.megadropCustom;
    },
    getUserAgent(state) {
      return state._internals?.req?.headers['user-agent'];
    },
    getNewTypeAheadConfig(state) {
      return state.new_type_ahead;
    },
    getVerticalName(state) {
      return state.verticalName;
    },
    getLayoutOptions(state) {
      return state.layout_options;
    },
    getPlaceType(state) {
      if (state.is_modal) return PLACE_TYPES.NOT_PLACE;
      if (state.home_context_menu) return PLACE_TYPES.HOME;
      if (state.context_menu) return PLACE_TYPES.PRODUCT_LIST;
      return PLACE_TYPES.NOT_PLACE;
    },
    isValidPlace(state, getters) {
      return getters.getPlaceType !== PLACE_TYPES.NOT_PLACE;
    },
    hasBrandCustomization(state) {
      return state.brandCustomization && state.brandCustomization.enabled;
    },
    getBrandCustomization(state) {
      return state.brandCustomization;
    },
    getSessionId(state) {
      return state.sessionId;
    },
    getIsAMV(state) {
      return state.isAMV;
    },
    hasParallaxSheet(state) {
      return state.has_parallax_sheet;
    },
    hasMountedParallaxFooterContainer(state) {
      return state.has_mounted_parallax_footer_container;
    },
    hasOpenVariantSelector(state) {
      return state.layout_options?.price_by_variant;
    },
    getChatbot(state) {
      return state.chatbot;
    },
    getChatbotUsers(state) {
      if (!state.layout_options?.chatbot_users) {
        return [];
      }

      if (!Array.isArray(state.layout_options?.chatbot_users)) {
        return state.layout_options?.chatbot_users.split(',');
      }

      return state.layout_options?.chatbot_users || [];
    },
    getChatbotSegments(state) {
      if (!state.layout_options?.chatbot_segments) {
        return [];
      }

      if (!Array.isArray(state.layout_options?.chatbot_segments)) {
        return state.layout_options?.chatbot_segments.split(',');
      }

      return state.layout_options?.chatbot_segments || [];
    },
    isOneclickEnabled(state) {
      return Boolean(state.layout_options?.oneclick_enabled);
    },
    getAssetsBase(state) {
      return state.assets_base;
    },
    getPlacements(state) {
      return state.placements;
    },
    isActiveSellerReport(state) {
      return state.layout_options?.activeSellerReport;
    },
    getIsFashion(state) {
      return state.is_fashion || false;
    },
    hasAcceptedCookies(state) {
      const cookiePolicyName = 'cookiesPolicy';
      if (state.isServer) {
        return {
          STRICT: false,
          ANALYTICS: false,
          PERSONALIZATION: false,
          ADVERTISING: false,
          VALORATION: false,
        };
      }
      const [STRICT = '0', ANALYTICS = '0', PERSONALIZATION = '0', ADVERTISING = '0', VALORATION = '0'] =
        state.gdprCookie ? state.gdprCookie : UtilsCookies.getCookieValue(cookiePolicyName);
      return {
        STRICT: STRICT !== '0',
        ANALYTICS: ANALYTICS !== '0',
        PERSONALIZATION: PERSONALIZATION !== '0',
        ADVERTISING: ADVERTISING !== '0',
        VALORATION: VALORATION !== '0',
      };
    },
    getCategories(state) {
      return state.categories || [];
    },
    isHome(state) {
      return state.is_home;
    },
    getGiftsWithStock(state) {
      return state.giftsWithStock;
    },
    getIsNewSearchExperience(state) {
      return state.new_search_experience;
    },
    getSearchSource(state) {
      return state.search_source;
    },
    getAlgoliaInfo(state) {
      return state.algoliaInfo;
    },
    getBasicInfo(state) {
      return state.basicInfo;
    },
    getShouldShowrealExpertButton(state) {
      return state.layout_options?.experts_time &&
             state.layout_options?.has_real_experts_chat
    },
    getVendors(state) {
      return state.vendors;
    },
    getIsNewPDP(state){
      return state.isNewPDP;
    }
  },
});

export default page;
