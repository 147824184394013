/**
 * @returns Returns an array with internal query params
 * @param {*} internals Contains query params from the url
 */
const paramsToKeepInQuery = ['zip', 'app', 'AMV', 'vuestore', 'stype', 'third'];
const SIMPLE_SEARCH_PARAM = 's';
const EXTENDED_SEARCH_PARAM = 'ss';

function addQueryParamsFromInternals(internals) {
  const queryParams = {};
  paramsToKeepInQuery.forEach(function (param) {
    if (internals && internals.req && param in internals.req.query) {
      queryParams[param] = internals.req.query[param];
    }
  });

  return queryParams;
}

function getSearchParamFromInternals(internals) {
  if (internals && internals.req && internals.req.query) {
    if (internals.req.query[SIMPLE_SEARCH_PARAM]) {
      return SIMPLE_SEARCH_PARAM;
    }
    if (internals.req.query[EXTENDED_SEARCH_PARAM]) {
      return EXTENDED_SEARCH_PARAM;
    }
  }
  return null;
}

function getQueryParamFromQuery(query) {
  const queryParams = {};
  paramsToKeepInQuery.forEach(function (param) {
    if (query && query[param]) {
      queryParams[param] = query[param];
    }
  });

  return queryParams;
}

function getSearchParamFromQuery(query) {
  if (query) {
    if (query[SIMPLE_SEARCH_PARAM]) {
      return SIMPLE_SEARCH_PARAM;
    }
    if (query[EXTENDED_SEARCH_PARAM]) {
      return EXTENDED_SEARCH_PARAM;
    }
  }
  return null;
}

/**
 * @returns Returns an array with internal query params and with sorting and searh query params
 * @param {*} internals Contains query params from the url
 * @param {*} selectedSorting Contains the selected sorting
 * @param {*} searchTerm Contains the search term introduced by the user
 * @param {*} searchImage Contains the searchImage url introduced by the user
 */
function updateSearchAndSortingParamsInURL(internals, selectedSorting, searchTerm, searchImage, selectedDeliveryType) {
  var queryParams = addQueryParamsFromInternals(internals);
  var searchParam = getSearchParamFromInternals(internals);

  if (selectedSorting && selectedSorting.key) {
    queryParams = Object.assign({
      ...queryParams,
      sorting: selectedSorting.key,
    });
  }

  if (searchTerm && searchParam === SIMPLE_SEARCH_PARAM) {
    queryParams = Object.assign({ s: searchTerm, ...queryParams });
  }

  if (searchTerm && searchParam === EXTENDED_SEARCH_PARAM) {
    queryParams = Object.assign({ ss: searchTerm, ...queryParams });
  }

  if (searchImage) {
    queryParams = Object.assign({ ...queryParams, image_id: searchImage });
  }

  if (selectedDeliveryType && selectedDeliveryType !== 'deliveryType') {
    queryParams = Object.assign({ ...queryParams, deliveryType: selectedDeliveryType });
  }

  return queryParams;
}

function updateNewSearchAndSortingParamsInURL(internals, selectedSorting, searchTerm, selectedDeliveryType) {
  let queryParams;
  let searchParam;

  if (internals.cleanInternal) {
    queryParams = getQueryParamFromQuery(internals.query);
    searchParam = getSearchParamFromQuery(internals.query);
  } else {
    queryParams = addQueryParamsFromInternals(internals);
    searchParam = getSearchParamFromInternals(internals);
  }

  if (searchTerm && searchParam === SIMPLE_SEARCH_PARAM) {
    queryParams = Object.assign({ s: searchTerm, ...queryParams });
  }

  if (searchTerm && searchParam === EXTENDED_SEARCH_PARAM) {
    queryParams = Object.assign({ ss: searchTerm, ...queryParams });
  }

  if (selectedDeliveryType && selectedDeliveryType !== 'deliveryType') {
    queryParams = Object.assign({ ...queryParams, deliveryType: selectedDeliveryType });
  }

  if (selectedSorting && selectedSorting.key) {
    queryParams = Object.assign({
      ...queryParams,
      sorting: selectedSorting.key,
    });
  }

  return queryParams;
}

/**
 * @returns Returns an string with the full url and the query params
 * @param {*} data Contains and uri item and a query item
 */
function getFullUrlWithQuery(data) {
  if (!data.url || !data.baseUrl) {
    console.error('ERROR:: Formating url. No correct params.', data);
    return '#';
  }
  try {
    const url = new URL(data.url, data.baseUrl);
    const paramsUrl = new URLSearchParams(url.searchParams);
    const urlQuery = Object.fromEntries(paramsUrl);
    const formatedQuery = addQueryParamsFromInternals(data.query);
    url.search = new URLSearchParams({
      ...formatedQuery,
      ...urlQuery,
      ...data.uniforms,
      ...data.search,
    });
    return url.pathname + url.search;
  } catch (error) {
    console.error('[ERROR] Formatting url. ', data.url, error);
    return data.url || 'https://www.elcorteingles.es/';
  }
}

/**
 * This function is used to form a url with query params
 * @param {*} location Current location of the page
 * @param {*} path Current url formatted
 * @param {*} query Query params
 * @returns
 */
function getUrlWithQuery(location, path, query) {
  if (!location || !path) {
    return console.error('getUrlWithQuery::NOT HOSTNAME', path, query);
  }
  const host = (location && location.origin) || undefined;
  const endpoint = path;
  const url = new URL(endpoint, host);
  const prevQuery = url.searchParams.toString();
  const actualQuery = new URLSearchParams(query).toString();
  url.search = new URLSearchParams(prevQuery + actualQuery);
  return url.toString();
}

/**
 * This function is used to form a url without query params
 * @param {*} location Current location of the page
 * @param {*} path Current url formatted
 * @param {*} query Query params
 */
function getUrl(host, path, query) {
  const endpoint = path + query;
  return new URL(endpoint, host);
}

/**
 * This function removes searches that will not be used
 * @param {*} url Where the user goes
 */
function sanitizeCestaSearchParams(redirectUrl) {
  const NEEDLESS_SEARCHS_PARAMS = ['color', 's', 'sorting'];
  const url = new URL(window.location.href);
  NEEDLESS_SEARCHS_PARAMS.forEach((search) => url.searchParams.delete(search));
  window.history.replaceState(null, null, url);
  url.pathname = redirectUrl;
  return url;
}

/**
 * This function removes params that will not be used
 * @param {*} url Where the user goes
 */
function sanitizeUrlParams(currentUrl) {
  try {
    const NEEDLESS_PARAMS = ['one_clic'];
    const url = new URL(currentUrl);
    NEEDLESS_PARAMS.forEach((search) => url.searchParams.delete(search));
    return url.href;
  } catch (error) {
    return currentUrl;
  }
}

/**
 * This function checks if the url points to a file
 * @param {*} url Where the user goes
 */
function urlPointsToImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

/**
 * This function gets an url parameter
 * @param {string} url Target url
 * @param {string} param Parameter name
 */
function getUrlParam(url, param) {
  const params = new URLSearchParams(url);
  return params.get(param);
}

function sanitize(term) {
  if (!term) return term;

  return term.replace(/[<>'"]/g, '');
}

function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

function getCurrentParam(key) {
  const newUrl = new URL(window.location.href);
  return newUrl.searchParams.get(key);
}

function hasParam(key, value) {
  const hasCurrentParam = getCurrentParam(key);
  if (hasCurrentParam && hasCurrentParam === value) return true;
  return false;
}

function getRouterNavigationMode(url) {
  try {
    const parsedURL = new URL(url);
    const [, navigationMode] = parsedURL.pathname.split('/');
    return navigationMode;
  } catch (error) {
    const [, navigationMode] = url.split('/');
    return navigationMode;
  }
}

function updateQueryParam(paramName, updateParam) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlSearchParam = urlSearchParams.get(paramName);
  if (!urlSearchParam) return;

  const updatedUrlSearchParam = updateParam(urlSearchParam);
  urlSearchParams.set(paramName, updatedUrlSearchParam);
  const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
  history.pushState({}, '', newUrl);
}

/**
 * Adds query params to existing URLs (inc merging duplicates)
 * @param {string} url - src URL to modify
 * @param {object} params - key/value object of params to add
 * @example
 * // returns /guides?tag=api
 * addQueryParamsToUrl('/guides?tag=hardware', { tag:'api' })
 * @example
 * // returns https://orcascan.com/guides?tag=api
 * addQueryParamsToUrl('https://orcascan.com/guides?tag=hardware', { tag: 'api' })
 * @returns {string} modified URL
 */
function addNewParamToQuery(url, params) {
  // if URL is relative, we'll need to add a fake base
  var fakeBase = !url.startsWith('http') ? 'http://fake-base.com' : undefined;
  var modifiedUrl = new URL(url || '', fakeBase);

  // add/update query params
  Object.keys(params).forEach(function (key) {
    if (modifiedUrl.searchParams.has(key)) {
      modifiedUrl.searchParams.set(key, params[key]);
    } else {
      modifiedUrl.searchParams.append(key, params[key]);
    }
  });

  // return as string (remove fake base if present)
  return modifiedUrl.toString().replace(fakeBase, '');
}

function slugify(str) {
  let string = '';
  if (str) {
    string = str.toLowerCase().trim();
  }
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç_';
  const to = 'aaaaaeeeeeiiiiooooouuuunc-';
  for (let chr = 0, len = from.length; chr < len; chr++) {
    string = string.replace(new RegExp(from.charAt(chr), 'g'), to.charAt(chr));
  }
  return string
    .replace(/ - /g, '-')
    .replace(/ \/ /g, '-')
    .replace(/\s{2,}/g, ' ')
    .replace(/^- /, '')
    .replace(/\&/g, ' and ')
    .replace(/ñ/g, 'n')
    .replace(/\_/g, '-')
    .replace(/(\s+)/g, '-')
    .replace(/[^\w-]+/g, '');
}

function removeAllQueryParams(url) {
  if (!url) {
    return '';
  }
  const indexOfQuestionMark = url.indexOf('?');
  if (indexOfQuestionMark >= 0) return url?.substring(0, url.indexOf('?'));
  return url;
}

function getURLWithoutDomainAndParams(url) {
  if (!url) {
    return '';
  }
  try {
    const urlObj = new URL(url);
    return urlObj.pathname;
  } catch (error) {
    return '';
  }
}

function URLHasParams(url) {
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams.toString() !== '';
  } catch (e) {
    console.error('URL inválida:', e);
    return false;
  }
}

function ensureSlash(url) {
  if (url?.charAt(url.length - 1) !== '/') {
    return url + '/';
  }
  return url;
}

module.exports = {
  addNewParamToQuery,
  ensureSlash,
  getCurrentParam,
  getFullUrlWithQuery,
  getRouterNavigationMode,
  getUrl,
  getUrlParam,
  getURLWithoutDomainAndParams,
  getUrlWithQuery,
  hasParam,
  isValidUrl,
  removeAllQueryParams,
  updateQueryParam,
  sanitize,
  sanitizeCestaSearchParams,
  sanitizeUrlParams,
  slugify,
  updateSearchAndSortingParamsInURL,
  updateNewSearchAndSortingParamsInURL,
  URLHasParams,
  urlPointsToImage,
};
