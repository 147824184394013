export default {
  getCartPath: payload => {
    const paths = [];
    const { is_from_checkout } = payload;    
    is_from_checkout ? paths.push('?is_from_checkout=true') : paths.push('?is_from_checkout=false');
    return paths.join('');
  },
  getTimeSlotPath: payload => {
    const { order_attempt_id, order_delivery_id } = payload;  
    const paths = ['/'];
    if(order_attempt_id) paths.push(order_attempt_id);
    paths.push('time_slots');
    if(order_delivery_id) paths.push(order_delivery_id);
    return paths.join('/');
  }
};
