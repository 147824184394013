const selectorState = () => ({
  namespaced: true,
  state: {
    isModalOpen: false,
    currentWrapper: null,
    currentProduct: '',
    buttonType: '',
  },
  mutations: {
    manageModal(state, payload) {
      document.body.style.overflowY = payload.isOpen ? 'hidden' : 'auto';
      document.body.style.touchAction = payload.isOpen ? 'none' : 'unset';
      state.isModalOpen = payload.isOpen;
      state.currentWrapper = payload.currentWrapper;
      state.currentProduct = payload.currentProduct;
      state.buttonType = payload.buttonType;
      state.showOnlyColors = payload.showOnlyColors;
    },
    setCurrentProduct(state, currentProduct) {
      state.currentProduct = currentProduct;
    },
    refreshState(state) {
      state.isModalOpen = false;
      state.currentWrapper = null;
      state.currentProduct = null;
      state.buttonType = null;
    },
  },
  actions: {
    async showModal({ commit }, payload) {
      await commit('manageModal', payload);
    },
    async setCurrentProduct({ commit }, currentProduct) {
      await commit('setCurrentProduct', currentProduct);
    },
    async refreshState({ commit }) {
      await commit('setCurrentProduct');
    },
  },
  getters: {
    getModalStatus(state) {
      return state.isModalOpen;
    },
    getCurrentWrapper(state) {
      return state.currentWrapper;
    },
    getCurrentProduct(state) {
      return state.currentProduct;
    },
    getShowOnlyColors(state) {
      return state.showOnlyColors;
    },
    getModalOpened(state) {
      return state.buttonType;
    },
  },
});

export default selectorState;
