const uniqBy = (array, key) => {
  if (!Array.isArray(array) || !key) {
    return;
  }
  let getValueByKeyInArray;
  if (typeof key === 'string') {
    const prop = key;
    getValueByKeyInArray = (item) => item[prop];
  }
  return array.filter((element, i, self) => {
    if (!(key in element)) {
      return;
    }
    return i === self.findIndex((y) => getValueByKeyInArray(element) === getValueByKeyInArray(y));
  });
};

module.exports = { uniqBy };
