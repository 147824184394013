
const mobileRegex = /Android|webOS|iPhone|iPad/i;

function isMobile(userAgent) { 
  if(import.meta.env.SSR){
    if(userAgent) {
      return mobileRegex.test(userAgent);
    }
  } else {
    if(window && window.navigator && window.navigator.userAgent) {
      return mobileRegex.test(window.navigator.userAgent);
    }
  }
  return false;
}

export default {
  isMobile
};
