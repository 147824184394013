/**
 * Enum for place types.
 * @readonly
 * @enum name of place
 */
const PLACE_TYPES = Object.freeze({
  PRODUCT_LIST: 'products_list',
  HOME: 'home',
  NOT_PLACE:'not_place'
});

export default PLACE_TYPES;
