import UtilsCookies from 'CommonUtils/cookies';

const isServer = typeof window === 'undefined';

const isIOS = () => {
  if (isServer) return false;
  const UA = window.navigator.userAgent;

  return /iPad|iPhone|iPod/.test(UA) && !window.MSStream;
};

const isAndroid = () => {
  if (isServer) return false;
  const UA = window.navigator.userAgent;

  return /android/i.test(UA);
};

const sendMessage = (event, data) => {
  const m = {};
  m[event] = data || {};

  if (isAndroid() && AppECI) {
    return AppECI.postMessage(JSON.stringify(m));
  }
  if (isIOS() && window.webkit) {
    return window.webkit.messageHandlers.AppECI.postMessage(m);
  }
  console.log('Mensaje enviado ' + event);
};

const appEvents = {
  messageUpdateCart(data) {
    sendMessage('postCartDone', data);
  },
  messageNeedLogin() {
    sendMessage('needLogin');
  },
  messageNativeShare(message) {
    sendMessage('socialNativeShare', message);
  },
  messageContinueShopping() {
    sendMessage('continueShopping');
  },
  messageEmptyCart() {
    sendMessage('postCartDone', { empty_basket: true, total_items: 0 });
  },
  messageOpenCameraGallery() {
    sendMessage('REQ_PHOTO');
  },
  messageCropEnd() {
    sendMessage('RES_CROP_END');
  },
  messageRequestAppReview() {
    sendMessage('requestAppReview');
  },
  messageSearchBarcode() {
    sendMessage('REQ_SUSTAINABILITY');
  },
  messageSessionCartUpdate() {
    const data = {};

    data.commerce_id = UtilsCookies.getCookieValue('commerce_id') || undefined;
    data['x-xsrf'] = UtilsCookies.getCookieValue('x-xsrf') || undefined;
    data.zip = UtilsCookies.getCookieValue('zip') || undefined;
    data.dyn_user_id = UtilsCookies.getCookieValue('dyn_user_id') || undefined;
    data.dyn_user_confirm =
      UtilsCookies.getCookieValue('dyn_user_confirm') || undefined;
    if(UtilsCookies.getCookieValue('order_attempt_id')) data.order_attempt_id = UtilsCookies.getCookieValue('order_attempt_id') || undefined;
    data.firefly_cart = UtilsCookies.getCookieValue('firefly_cart') === 'true' ? true : false;
    sendMessage('sessionCartUpdate', data);
  },
  messageSendUnits(total_items) {
    sendMessage('setCartUnits', { units: total_items || 0 });
  }
};

export default appEvents;
