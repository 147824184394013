import ServiceAPI from './ServiceAPI';

class NewsletterService extends ServiceAPI {
  constructor(endpoint) {
    super(endpoint);
  }

  async postNewsletterSub(options) {
    const endpoint = '/newsletter/subscription';
    return await this.post(options, endpoint);
  }
}


export default NewsletterService;