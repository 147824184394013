import ServiceAPI from 'Services/ServiceAPI';

class Buylater extends ServiceAPI {
  constructor(endpoint) {
    super(endpoint);
  }

  async getBuyLater(options) {
    let endpoint;

    if(options) {
      const {currentPage,itemsPerPage} = options;
      endpoint = `?page=${currentPage}&itemsPerPage=${itemsPerPage}`;
    }else{
      endpoint = '?page=1&itemsPerPage=3';
    }

    return await this.get(endpoint);
  }

  async addBuyLater(productId, sku, product_name) {
    const service = 'add';
    const body = { productId, sku, product_name };
    return await this.post(body, service);
  }
  async deleteBuyLater(sku) {
    const service = 'delete';
    const params = { sku };
    return await this.delete(service, params);
  }
}

export default Buylater;
