function productCustomizationMapper(productCustomizationData, skuReference) {
  /* const productCustomizationData = {
    rule_id: 'e6cba78c-96da-47d6-891c-41b691c30013',
    associated_service: '001003545400024',
    headline: 'PERSONALIZAR PERFU',
    description_headline:
      'Personalización de perfumería. No se permiten cambios ni devoluciones.',
    max_characters: '5',
    fonts: [
      {
        font_name: 'Serif-Italic',
      },
    ],
    colors: [
      {
        hexadecimal: '#B4C8C2',
        color_name: 'Surf Spray',
      },
      {
        hexadecimal: '#7D2027',
        color_name: 'Red Dahlia',
      },
      {
        hexadecimal: '#DE3848',
        color_name: 'Hibiscus',
      },
    ],
    service_reference: '001003545400024',
  }; */
  if (!productCustomizationData) {
    return undefined;
  }
  return {
    ruleId: productCustomizationData.rule_id,
    associatedService: productCustomizationData.associated_service,
    headLine: productCustomizationData.headline,
    headlineDescription: productCustomizationData.description_headline,
    maxCharacterNumber: Number(productCustomizationData.max_characters ?? '0'),
    fonts: mapFonts(productCustomizationData.fonts),
    colors: mapColors(productCustomizationData.colors),
    price: formatPrice(productCustomizationData.service_price ?? '0 €'),
    reference: productCustomizationData.service_reference || skuReference,
  };
}

function mapFonts(fonts) {
  if (fonts && Array.isArray(fonts) && fonts.length) {
    return fonts.map((font) => {
      return { name: font.font_name };
    });
  }
  return [];
}

function mapColors(colors) {
  if (colors && Array.isArray(colors) && colors.length) {
    return colors.map((color) => {
      return {
        hexValue: color.hexadecimal,
        name: color.color_name,
      };
    });
  }
  return [];
}

function formatPrice(price) {
  return price.replace('EUR', '€');
}

module.exports = {
  productCustomizationMapper,
};
