import ServiceAPI from 'Services/ServiceAPI';
import UtilsURL from 'CommonUtils/productsList';
import SkuMapper from 'CommonUtils/mappers/sku_colors.mapper';
const ONLY_PLAIN_IMAGES = true;
import axios from 'axios';

class APIService extends ServiceAPI {
  constructor(endpoint) {
    super(endpoint);
  }

  async getProductsList(options) {
    const path = UtilsURL.getSlugsPath(options);
    const endpoint = options.isUniforms ? '' : '/products_list';
    return await this.get(endpoint + path, {
      deliveryType: options.deliveryType,
      showDimensions: options.showDimensions,
      isAMV: options.isAMV,
      ...(options.isUniforms && { schoolId: options.schoolId }),
      ...(options.third && { third: options.third }),
    });
  }

  async getProductData(options) {
    const id = options.product_id;
    const endpoint = '/product-data/' + id;
    return await this.get(endpoint);
  }

  async getProductsListBySearch(options) {
    const path = UtilsURL.getSlugsPathBySearch(options);
    const endpoint = '/search';
    return await this.get(endpoint + path, {
      deliveryType: options.deliveryType,
      showDimensions: options.showDimensions,
    });
  }

  async getProductsListByNewSearch(options) {
    const path = UtilsURL.getSlugsPathByNewSearch(options);
    const endpoint = '/new-search';
    return await this.get(endpoint + path, {
      deliveryType: options.deliveryType,
      showDimensions: options.showDimensions,
      sorting: options.sorting,
      stype: options.stype,
      isHome: options.isHome,
      isBookSearch: options.isBookSearch
    });
  }

  async getSearchProducts(options) {
    const { term } = options;
    const endpoint = `/typeahead/?term=${encodeURI(term)}`;
    return await this.get(endpoint);
  }

  async getSearchProductsV2(options) {
    const {
      term,
      query: { v },
    } = options;
    const endpoint = `/typeahead/2/?term=${encodeURI(term)}${v ? `&v=${v}` : ''}`;
    return await this.get(endpoint);
  }

  async postSearchImage({ formData, uri }) {
    return await this.post(formData, uri);
  }

  async getStock(options) {
    const ids = options.ids;
    const type = options.type;
    const endpoint = '/stock';
    const params = {};
    const additionalParams = options.additionalParams ? options.additionalParams : [];
    params[type === 'product' ? 'products' : type] = ids.join(',');
    if (additionalParams.collectCenterId) params['collectCenterId'] = additionalParams.collectCenterId;
    if (additionalParams.deliveryPostalCodeId) params['deliveryPostalCodeId'] = additionalParams.deliveryPostalCodeId;
    if (additionalParams.eciExpressCentreId) params['eciExpressCentreId'] = additionalParams.eciExpressCentreId;
    if (additionalParams.AMV) params['AMV'] = additionalParams.AMV;
    return await this.get(endpoint, params);
  }

  async getSimilarProducts(options) {
    const endpoint = `/similar-images/${options.id}/${options.mad_id}/`;
    return this.getCrossSellingProducts(endpoint);
  }

  async getCrossSellProducts(options) {
    const endpoint = `/cross-sell/${options.product_id}/${options.reference_id}/`;
    return this.getCrossSellingProducts(endpoint);
  }

  async getCrossSellingProducts(endpoint) {
    const params = { view_json: 1 };
    let products = [];
    let dataLayer = [];
    let isFirefly = false;
    await this.get(endpoint, params).then((response) => {
      (products = response.products),
        (dataLayer = response._datalayer && response._datalayer[0]),
        (isFirefly = response.isFirefly);
    });
    if (!products || !products.length) {
      return new Promise((resolve, reject) => {
        return reject({ products: [], _datalayer: [] });
      });
    }

    if (!isFirefly) {
      products.forEach((product) => {
        product['_my_colors'] = SkuMapper.skuColorsMapper(
          product.skus,
          product.image.color,
          {},
          ONLY_PLAIN_IMAGES,
          product._providers,
          product._selected_provider,
        );
      });
    }

    return new Promise((resolve) => {
      return resolve({ products, _datalayer: dataLayer });
    });
  }

  async getRelatedProducts(options) {
    const endpoint = `/related-products/${options.id?.trim()}`;
    const params = { category_id: options.dataLayer?.category, categoryName: options.dataLayer?.hierarchy };

    const { products, _datalayer } = await this.get(endpoint, params);

    return new Promise((resolve, reject) => {
      if (!products?.length) {
        return reject({ products: [], _datalayer: [] });
      }
      return resolve({ products, _datalayer });
    });
  }

  async getRichRelevanceProducts(options) {
    const endpoint = `/rich-relevance/get-recomendations`;
    let richRelevanceData = {};
    const dataLayer = [];
    await this.get(endpoint, options).then((response) => {
      richRelevanceData = response.placements;
    });
    if (!richRelevanceData) {
      return new Promise((reject) => {
        return reject({ richRelevanceData: [], _datalayer: [] });
      });
    }

    return new Promise((resolve) => {
      return resolve({ ...richRelevanceData, _datalayer: dataLayer });
    });
  }

  async getCentres(options) {
    const endpoint = '/centres-with-product-stock';
    const params = {};
    params.reference = options.sku;
    params.province = options.province || 28;
    params.siteId = options.storeId || 'eciStore';
    return await this.get(endpoint, params);
  }

  async getProvinces(options) {
    const endpoint = '/get-provinces-with-centres';
    const params = {};
    params.siteId = options?.storeId || 'eciStore';
    params.reference = options?.sku;
    return await this.get(endpoint, params);
  }

  async getClickNCarCentres(options) {
    const params = {};
    params.province = options.province || 28;
    params.siteId = options.storeId || 'eciStore';
    params.eciReference = options.eciReference;

    const endpoint = '/click-and-car/provinces/' + params.province + '/centres';
    return await this.get(endpoint, params);
  }

  async getClickNCarProvinces(options) {
    const endpoint = '/click-and-car/provinces';
    const params = {};
    params.siteId = options.storeId || 'eciStore';
    params.eciReference = options.eciReference;

    return await this.get(endpoint, params);
  }

  async subscribeCustomerNotification(options) {
    const endpoint = '/customer-notification';
    return await this.post(options, endpoint);
  }

  async getBuyLookArtwork(options) {
    const endpoint = `/sheets/artwork/${options.id}`;
    return await this.get(endpoint);
  }

  async getFastVPR(options) {
    const endpoint = `/fast-vpr/${options.zip}/${options.reference}`;
    return await this.get(endpoint);
  }

  async setProductDetailForm(options) {
    const endpoint = `/form/${options.type}/${options.reference}`;
    return await this.post(options.form, endpoint);
  }

  async getProductDataViewJson(productId, color) {
    const endpoint = '/product-detail/' + productId;
    return await this.get(endpoint, { color });
  }

  postChatbotMessage(payload) {
    const endpoint = '/chatbot-chat';
    return this.postCancellable(payload, endpoint);
  }

  postExpertsChatbotMessage(payload) {
    const endpoint = '/chatbot-experts-chat';
    return this.postCancellable(payload, endpoint);
  }

  postExpertsChatbotSummarizer(payload) {
    const endpoint = '/chatbot-experts-summarizer';
    return this.postCancellable(payload, endpoint);
  }
  
  postChatbotMoreProducts(payload) {
    const endpoint = '/chatbot-show-more';
    return this.postCancellable(payload, endpoint);
  }

  postChatbotFeedback(payload) {
    const endpoint = '/chatbot-feedback';
    return this.postCancellable(payload, endpoint);
  }

  getRealExpertsRequestId(payload) {
    const endpoint = '/chat-real-experts-request-id';
    return this.postCancellable(payload, endpoint);
  }

  getRealExpertsDetails(payload) {
    const endpoint = '/chat-real-experts-details';
    return this.postCancellable(payload, endpoint);
  }

  realExpertsFinalizeChat(payload){
    const endpoint = '/chatbot-experts-finalize';
    return this.postCancellable(payload, endpoint);
  }

  getRealExpertsAvailable(payload){
    const endpoint = '/chatbot-experts-available-experts';
    return this.postCancellable(payload, endpoint);
  }

  async getChatbotCheckFinalities(payload) {
    const endpoint = '/check-finalities';
    return await this.post(payload, endpoint);
  }
  async postChatbotSaveConsent(payload) {
    const endpoint = '/save-consent';
    return await this.put(payload, endpoint);
  }
  async postChatbotEraseConsent(payload) {
    const endpoint = '/erase-consent';
    return await this.put(payload, endpoint);
  }
  async getLocationSuggestions(term) {
    const endpoint = '/uniforms/typeahead/?term=' + term;
    return await this.get(endpoint);
  }
  async getSchoolSuggestions(payload) {
    const searchTerm = payload?.inputText ? payload.inputText : payload;
    let endpoint = '/uniforms/schools_map?search=' + searchTerm;
    if (payload?.province && payload?.city) {
      endpoint = '/uniforms/schools_map/' + payload.province + '/' + payload.city;
    }
    if (payload?.isCollective) {
      endpoint = '/uniforms/schools_map?collective=' + searchTerm;
    }
    return await this.get(endpoint);
  }
  async getCatalogMultiProducts(payload) {
    const endpoint = '/catalog_multi_products/';
    return await this.get(endpoint, payload);
  }
  async getUserDemographics(payload) {
    const endpoint = '/get-user-demographics';
    return await this.get(endpoint, payload);
  }
  async getShoppingPreferences(payload) {
    const endpoint = '/get-shopping-preferences';
    return await this.get(endpoint, payload);
  }
  async getDemographicsAndShoppingPreferences(payload) {
    const endpoint = '/get-demographics-and-shopping-preferences';
    return await this.get(endpoint, payload);
  }
  getProvince(payload) {
    const provinces = {
      Trazado_9052: 'la-coruna',
      Trazado_9049: 'lugo',
      Trazado_9094: 'pontevedra',
      Trazado_9051: 'orense',
      Trazado_8992: 'asturias',
      Trazado_9041: 'cantabria',
      Trazado_8990: 'vizcaya',
      Trazado_9009: 'guipuzcoa',
      Trazado_9019: 'alava',
      Trazado_9012: 'navarra',
      Trazado_8994: 'la-rioja',
      Trazado_8999: 'huesca',
      Trazado_9013: 'zaragoza',
      Trazado_8991: 'teruel',
      Trazado_9083: 'gerona',
      Trazado_8996: 'barcelona',
      Trazado_8997: 'tarragona',
      Trazado_8998: 'lerida',
      Trazado_8995: 'castellon',
      // Trazado_9007: 'valencia',
      Trazado_9008: 'valencia',
      Trazado_9053: 'alicante',
      Trazado_9055: 'murcia',
      Trazado_9072: 'baleares',
      Trazado_9069: 'baleares',
      Trazado_9064: 'baleares',
      Trazado_9062: 'baleares',
      Trazado_9060: 'baleares',
      Trazado_9105: 'tenerife',
      Trazado_9103: 'tenerife',
      Trazado_9106: 'tenerife',
      Trazado_9104: 'tenerife',
      Trazado_9102: 'las-palmas',
      Trazado_9101: 'las-palmas',
      Trazado_9100: 'las-palmas',
      Trazado_9098: 'las-palmas',
      Trazado_9048: 'caceres',
      Trazado_9047: 'badajoz',
      Trazado_9081: 'ceuta',
      Trazado_9097: 'melilla',
      Trazado_9001: 'almeria',
      Trazado_8987: 'granada',
      Trazado_9018: 'malaga',
      Trazado_9077: 'cadiz',
      Trazado_9058: 'huelva',
      Trazado_9040: 'sevilla',
      Trazado_9035: 'cordoba',
      Trazado_9014: 'jaen',
      Trazado_9039: 'madrid',
      Trazado_9025: 'guadalajara',
      Trazado_9006: 'cuenca',
      Trazado_9016: 'albacete',
      Trazado_9045: 'ciudad real',
      Trazado_9044: 'toledo',
      Trazado_9050: 'leon',
      Trazado_9020: 'palencia',
      Trazado_9033: 'burgos',
      Trazado_9029: 'burgos',
      Trazado_9003: 'soria',
      Trazado_8986: 'zamora',
      Trazado_9043: 'salamanca',
      Trazado_9042: 'valladolid',
      Trazado_9037: 'avila',
      Trazado_9036: 'segovia',
    };
    const provinceId = payload.provinceId;
    const province = provinces[provinceId];
    if (!province) return;
    return province;
  }
  async getPDPAndStock(ean) {
    const endpoint = `/api/pdp-stock/${ean}`;
    return await this.get(endpoint);
  }

  async getMegadrop() {
    const endpoint = `/megadrop/complete`;
    return await this.get(endpoint);
  }

  async getCentersByPostalCode(postalCode) {
    const endpoint = `/centres-by-postal-code/${postalCode}`;
    return await this.get(endpoint);
  }

  async getCustomerData(type, payload) {
    const endpoint = `/text-book/customer/${type}/${payload}`;
    return await this.get(endpoint);
  }

  async getTextBooksProvinces() {
    const endpoint = `/text-book/provinces/`;
    return await this.get(endpoint);
  }

  async getLocalitiesByProvince(payload) {
    const endpoint = `/text-book/localities/${payload}`;
    return await this.get(endpoint);
  }

  async getTeachingCentersByLocality(payload) {
    const endpoint = `/text-book/teaching-centers/${payload}`;
    return await this.get(endpoint);
  }

  async getCourses(payload) {
    const endpoint = `/text-book/schools/${payload}`;
    return await this.get(endpoint);
  }
  async getRetailMediaDisplayAd(options) {
    const endpoint = `/custom/retail-media/display`;
    return await this.post(options, endpoint);
  }

  async getRetailMediaProductAds(options) {
    const endpoint = `/custom/retail-media/products`;
    return await this.post(options, endpoint);
  }

  async getRetailMediaTracking(url){
    return await axios.get(url, {});
  }

  getBasicInfo() {
    const endpoint = `/basic-info`;
    return this.get(endpoint);
  }
}

export default APIService;
