const SIMPLE_SEARCH_PARAM = 's';
const EXTENDED_SEARCH_PARAM = 'ss';

const _getQueryString = (queryObj) => {
  const keys = Object.keys(queryObj);
  if (!keys.length) return '';
  return (
    '?' +
    keys
      .filter(Boolean)
      .map(function (key) {
        return key + '=' + queryObj[key];
      })
      .join('&')
  );
};

const _getPathString = (pathnamesList, route) => {
  const pathnames = pathnamesList.join('/');
  if (route?.name && route?.name === 'preview_site_manager') {
    return `/${pathnames}/store_preview_path/`;
  }
  return `/${pathnames}/`;
};

export default {
  getSlugsPath: (payload) => {
    const paths = [];
    const queryObj = {};

    const { slugs, filters, page, sorting, sortHasBeenClicked, image_id, route } = payload;
    const { hierarchy, brand, artist, role } = slugs;
    const searchfilter = payload.searchfilter && payload.searchfilter.slug;
    const hierarchies = hierarchy && hierarchy.join('/');

    if (searchfilter) paths.push(searchfilter);
    if (brand) paths.push(brand);
    if (artist && role) paths.push(`bio/${artist}/${role}`);
    if (hierarchy) paths.push(hierarchies);
    if (filters && filters.length) paths.push(filters);

    if (page && page > 1) paths.push(page);
    if (sortHasBeenClicked && sorting && sorting.key) {
      queryObj.sorting = sorting.key;
    }

    if (image_id) {
      queryObj.image_id = image_id;
      queryObj.stype = 'image';
    }
    const query = _getQueryString(queryObj);
    const pathnames = _getPathString(paths, route);
    return pathnames + query;
  },

  getNewSearchPath: (payload) => {
    const paths = [];
    const queryObj = {};
    const { slugs, filters, page, searchTerm, searchType, sorting, sortHasBeenClicked } = payload;
    const hierarchies = slugs && slugs.join('/');

    if (slugs?.length) paths.push(hierarchies);

    paths.push('search-nwx');

    if (filters && filters.length) paths.push(filters);
    if (page && page > 1) {
      paths.push(page);
    } else {
      paths.push(1);
    }
    if (searchTerm) {
      if (searchType === SIMPLE_SEARCH_PARAM) {
        queryObj.s = searchTerm.replace('/', '');
      }
      if (searchType === EXTENDED_SEARCH_PARAM) {
        queryObj.ss = searchTerm.replace('/', '');
      }
    }
    if (sortHasBeenClicked && sorting && sorting.key) queryObj.sorting = sorting.key;
    const query = _getQueryString(queryObj);
    const pathnames = _getPathString(paths);
    return pathnames + query;
  },
  getSlugsPathBySearch: (payload) => {
    const paths = [];
    const queryObj = {};
    const { slugs, filters, page, searchTerm, searchType, sorting, sortHasBeenClicked, image_id } = payload;
    const { hierarchy } = slugs;
    const searchfilter = payload.searchfilter && payload.searchfilter.slug;
    const hierarchies = hierarchy && hierarchy.join('/');

    if (searchfilter) paths.push(searchfilter);
    if (hierarchy) paths.push(hierarchies);

    paths.push('search');

    if (filters && filters.length) paths.push(filters);
    if (page && page > 1) {
      paths.push(page);
    } else {
      paths.push(1);
    }
    if (searchTerm) {
      if (searchType === SIMPLE_SEARCH_PARAM) {
        queryObj.s = searchTerm.replace('/', '');
      }
      if (searchType === EXTENDED_SEARCH_PARAM) {
        queryObj.ss = searchTerm.replace('/', '');
      }
    }
    if (sortHasBeenClicked && sorting && sorting.key) queryObj.sorting = sorting.key;

    if (image_id) {
      queryObj.image_id = image_id;
      queryObj.stype = 'image';
    }

    const query = _getQueryString(queryObj);
    const pathnames = _getPathString(paths);
    return pathnames + query;
  },
  getSlugsPathByNewSearch: (payload) => {
    const paths = [];
    const queryObj = {};
    const { slugs, filters, page, searchTerm, searchType, sorting, sortHasBeenClicked, image_id } = payload;
    const hierarchy = slugs;
    const searchfilter = payload.searchfilter && payload.searchfilter.slug;
    const hierarchies = hierarchy && hierarchy.join('/');

    if (searchfilter) paths.push(searchfilter);
    if (hierarchy?.length) paths.push(hierarchies);

    if (filters && filters.length) paths.push(filters);
    if (page && page > 1) {
      paths.push(page);
    } else {
      paths.push(1);
    }
    if (searchTerm) {
      if (searchType === SIMPLE_SEARCH_PARAM) {
        queryObj.s = searchTerm.replace('/', '');
      }
      if (searchType === EXTENDED_SEARCH_PARAM) {
        queryObj.ss = searchTerm.replace('/', '');
      }
    }
    if (sortHasBeenClicked && sorting && sorting.key) queryObj.sorting = sorting.key;

    if (image_id) {
      queryObj.image_id = image_id;
      queryObj.stype = 'image';
    }
    const query = _getQueryString(queryObj);
    const pathnames = _getPathString(paths);
    return pathnames + query;
  },
};
