import API from 'Services/cart';
import DatalayerAPI from 'Services/Datalayer';
const Service = new API('/api/vuestore');
const DatalayerService = new DatalayerAPI('/api/firefly/vuestore');
import UtilsCartErrors from 'CommonUtils/errors/cartFormatError.js';
import UtilsCart from 'CommonUtils/formatCartObject';
import UtilsFidelityPoints from 'CommonUtils/fidelityPoints.js';
import ICON_NAMES from 'CommonUtils/iconNames';
import CART_ACTION_TYPES from 'CommonUtils/cartActionTypes';
import appEvents from 'CommonUtils/appEvents.js';
import cookiesUtils from 'CommonUtils/cookies.js';
import CartUtils from 'CommonUtils/schemas/cart.js';
import UtilsLocalStorage from 'CommonUtils/localStorage.js';
import URLUtils from 'CommonUtils/url.js';

const sendAppEvents = () => {
  appEvents.messageSessionCartUpdate();
};

const cartActions = async function ({ commit, dispatch }, response) {
  const {
    checkout_url,
    csc_url,
    user,
    guest_email,
    gift_receipt_enabled,
    shipping_prime_activated,
    shipping_prime_infos,
    line_items,
    additional_data,
    summary,
    total_items,
    order_discounts,
    eci_card_data,
    order_attempt_id,
    fake: isFake,
    eci_express: eciExpressInCart,
    click_and_car: clickAndCarInCart,
  } = response;

  commit('setErrorModal', {
    status: false,
    msg: '',
  });

  commit('setErrorEciCard', {
    status: false,
    msg: '',
  });

  commit('setErrorCoupon', {
    status: false,
    msg: '',
  });

  if (gift_receipt_enabled) {
    commit('setGiftReceiptEnabled', gift_receipt_enabled);
  }

  if (shipping_prime_activated) {
    commit('setShippingPrimeActivated', shipping_prime_activated);
  } else {
    commit('setShippingPrimeActivated', false);
  }

  if (shipping_prime_infos) {
    commit('setShippingPrimeInfos', shipping_prime_infos);
  } else {
    commit('setShippingPrimeInfos', null);
  }

  if (user && user.status !== 0) {
    commit('setUser', user);
    commit('setHasLoyaltyPoints', user._show_loyalty_points);

    commit('setAlgoliaUserData', 'user-' + user.id);
    if (user._show_loyalty_points) {
      const points = await UtilsFidelityPoints.handlerMrca();
      commit('setLoyalty', points);
    } else {
      UtilsFidelityPoints.deleteMrca();
      commit('setLoyalty', null);
    }

    dispatch('wishlist/initWishlist', null, { root: true });
  } else {
    UtilsFidelityPoints.deleteMrca();
    commit('setHasLoyaltyPoints', false);
    commit('setLoyalty', null);
    commit('wishlist/removeWishlist', null, { root: true });
    
    dispatch('page/removeBasicInfo', null, { root: true });
  }
  if (order_attempt_id) {
    commit('setOrderAttemptId', order_attempt_id);
  }

  if (isFake) {
    commit('setResetFakeCartStore');
    commit('setIsFireFlyCart', true);
  }
  if (cookiesUtils.getCookieValue('firefly_cart') !== 'true') {
    commit('setIsFireFlyCart', false);
  }

  //TODO: [FF-mini-cesta] error al mergear go to omnistore
  // if (order_attempt_id) {
  //   cookiesUtils.deleteCookie('order_attempt_id');
  //   cookiesUtils.deleteCookie('isFireFlyCart');
  //   cookiesUtils.deleteCookie('fireflyMerge');
  //   commit('setOrderAttemptId', order_attempt_id);
  //   commit('setIsFireFlyCart', true);
  //   commit('setIsMerged', true);
  // }
  const eci_express = UtilsLocalStorage.getJSONItem('EciExpress');
  const click_and_car = UtilsLocalStorage.getJSONItem('ClickNCar');

  commit('setGuestEmail', guest_email || null);
  commit('setEciCard', eci_card_data || '');
  commit('setItems', { line_items: line_items || {}, total_items });
  commit('setAdditionalData', additional_data || []);
  commit('setDiscounts', order_discounts || []);
  commit('setSummary', summary || {});
  commit('setEciExpress', eci_express || {});
  commit('setclicAndCar', click_and_car || {});
  commit('setCheckoutUrl', checkout_url);
  commit('setIsCSC', csc_url);
  commit('setEciExpressInCart', eciExpressInCart);
  commit('setClickAndCarInCart', clickAndCarInCart);
};

const errorMigrationBalancerFF = (identifier, dispatch) => {
  dispatch('showErrorToast', {
    status: true,
    msg: `checkout.errors.${identifier}`,
  });
};

const errorActions = ({ commit, dispatch }, state, error, payload = {}) => {
  if (!error) return;
  if (error?.data?.code === 'MSH_SESSION_EXPIRED') {
    if (payload?.line_items) window.sessionStorage.setItem('msh_session_expired_payload', JSON.stringify(payload));
    return window.location.reload();
  }

  const { errors } = error.data;

  const { code, params } = errors[0];
  const errorObject = UtilsCartErrors.cartFormatError(errors[0]);

  if (code === 'C409' && state.attempts < 1) {
    commit('setAttempts', state.attempts + 1);
    dispatch('getCart').then(() => {
      dispatch('postCart', payload);
    });
    return;
  }

  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.MODAL) {
    return commit('setErrorModal', {
      status: true,
      msg: errorObject.msg,
      param: errorObject.param,
    });
  }

  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.ECI_CARD) {
    return commit('setErrorEciCard', {
      status: true,
      msg: errorObject.msg,
    });
  }

  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.EXPIRED_CART) {
    return commit('setErrorExpiredCart', {
      status: true,
      msg: errorObject.msg,
    });
  }

  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.COUPON) {
    return commit('setErrorCoupon', {
      status: true,
      msg: errorObject.msg,
    });
  }

  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.EXPIRED_TIME_SLOT) {
    window.location.reload();
    return;
  }

  //TODO: REVISAR PARA LOS TIME SLOTS DE CLICK N CAR
  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.TOAST) {
    commit('setEciExpressTimeSlots', []);
    commit('setClicknCarTimeSlots', []);
    return dispatch('showErrorToast', {
      status: true,
      msg: errorObject.msg,
    });
  }

  if (errorObject.show.type == UtilsCartErrors.SHOW_ERROR_TYPE.PRODUCT) {
    const total_items = state.total_items;
    const line_items = state.items.map((product) => {
      const aux_product = product;
      if (aux_product.reference === params[0][0] || aux_product.id === params[0][0]) {
        aux_product.description = errorObject.msg;
        return aux_product;
      }
      return aux_product;
    });
    return commit('setItems', { line_items, total_items });
  }
};

const cart = () => ({
  namespaced: true,
  state: {
    additional_data: [],
    apiResponse: {},
    attempts: 0,
    centerDeliveryType: 0,
    clicAndCar: {},
    clickNcarTimeSlots: [],
    clickAndCarValueInCart: null,
    currentDeliveryType: null,
    eciExpress: {},
    eciExpressTimeSlots: [],
    eciExpressValueInCart: null,
    eci_card: '',
    errorCouponMsg: '',
    errorEciCardMsg: '',
    errorExpiredCartMsg: '',
    errorModalMsg: '',
    errorModalParam: '',
    fireflyMerge: false,
    gift_receipt_enabled: false,
    guest_email: '',
    hasLoyaltyPoints: false,
    items: [],

    isFireFlyCart: false,
    isLoading: true,
    isLogged: false,
    loadStatus: CartUtils.STATUS.NOT_LOADED,
    loyalty: null,
    orderAttemptId: '',
    order_discounts: [],
    preferred_zip: '',
    redirectUrlBasket: '/compra/tramitacion/cesta',
    shipping_prime_activated: false,
    shipping_prime_infos: null,
    showCouponError: false,
    showEciCardError: false,
    showErrorExpiredCart: false,
    showErrorModal: false,
    status: '',
    summary: {},
    total_items: null,
    user: {},
    userDemographics: {},
    zip: '',
    algoliaAuthenticatedUserToken: '',
  },
  mutations: {
    setInitCart(state, payload) {
      state.orderAttemptId = payload.orderAttemptId;
      state.isFireFlyCart = payload.isFireFlyCart;
      state.fireflyMerge = payload.fireflyMerge;
    },
    setIsFireFlyCart(state, payload) {
      state.isFireFlyCart = payload;
    },
    setIsMerged(state, payload) {
      state.fireflyMerge = payload;
    },
    setOrderAttemptId(state, payload) {
      state.orderAttemptId = payload;
      cookiesUtils.setCookie('order_attempt_id', payload);
    },
    setResetFakeCartStore(state) {
      state.orderAttemptId = '';
      cookiesUtils.deleteCookie('order_attempt_id');
      cookiesUtils.deleteCookie('firefly_merge');
    },
    setAttempts(state, payload) {
      state.attempts = payload;
    },
    setUser(state, payload) {
      state.user = payload;
      state.isLogged = !!payload;
    },
    setEciCard(state, payload) {
      state.eci_card = payload;
    },
    setGuestEmail(state, payload) {
      state.guest_email = payload;
    },
    setItems(state, payload) {
      state.items = payload.line_items;
      state.total_items = payload.total_items;
    },
    setSummary(state, payload) {
      state.summary = payload;
    },
    setGiftReceiptEnabled(state, payload) {
      state.gift_receipt_enabled = payload;
    },
    setAdditionalData(state, payload) {
      state.additional_data = payload;
    },
    setDiscounts(state, payload) {
      state.order_discounts = payload;
    },
    setShippingPrimeActivated(state, payload) {
      state.shipping_prime_activated = payload;
    },
    setShippingPrimeInfos(state, payload) {
      state.shipping_prime_infos = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
    setApiResponse(state, payload) {
      state.apiResponse = payload;
    },
    setEciExpress(state, payload) {
      state.eciExpress = payload;
      if (payload?.postal_code) state.zip = payload.postal_code;
    },
    setEciExpressTimeSlots(state, payload) {
      state.eciExpressTimeSlots = payload;
    },
    setClicknCarTimeSlots(state, payload) {
      state.clickNcarTimeSlots = payload;
    },
    setclicAndCar(state, payload) {
      state.clicAndCar = payload;
    },
    setErrorModal(state, payload) {
      const { status, msg, param } = payload;
      state.showErrorModal = status;
      state.errorModalMsg = msg;
      state.errorModalParam = param;
    },
    setErrorExpiredCart(state, payload) {
      const { status, msg } = payload;
      state.showErrorExpiredCart = status;
      state.errorExpiredCartMsg = msg;
    },
    setErrorEciCard(state, payload) {
      const { status, msg } = payload;
      state.showEciCardError = status;
      state.errorEciCardMsg = msg;
    },
    setErrorCoupon(state, payload) {
      const { status, msg } = payload;
      state.showCouponError = status;
      state.errorCouponMsg = msg;
    },
    setCenterDeliveryType(state, payload) {
      state.centerDeliveryType = payload;
    },
    setCurrentDeliveryType(state, payload) {
      state.currentDeliveryType = payload;
    },
    setCheckoutUrl(state, payload) {
      state.redirectUrlBasket = payload || state.redirectUrlBasket;
    },
    setIsCSC(state, payload) {
      state.isCSC = payload ? true : false;
    },
    setEciExpressInCart(state, eciExpressValueInCart) {
      state.eciExpressValueInCart = eciExpressValueInCart;
    },
    setClickAndCarInCart(state, clickAndCarValueInCart) {
      state.clickAndCarValueInCart = clickAndCarValueInCart;
    },
    setLoyalty(state, payload) {
      state.loyalty = payload;
    },
    setHasLoyaltyPoints(state, payload) {
      state.hasLoyaltyPoints = payload;
    },
    setLoadStatus(state, status) {
      state.loadStatus = status;
    },
    setUserDemographics(state, demographics) {
      state.userDemographics = demographics;
    },
    setAlgoliaUserData(state, payload) {
      state.algoliaAuthenticatedUserToken = payload;
    },
  },
  actions: {
    initMountCart({ commit }) {
      //Disabling ff cart for CSC
      if (URLUtils.hasParam('isCSC', 'true')) {
        cookiesUtils.deleteCookie('ff_checkout');
        cookiesUtils.setCookie('firefly_cart', 'false');
      }
      const orderAttemptId = cookiesUtils.getCookieValue('order_attempt_id');
      const isFireFlyCart = cookiesUtils.getCookieValue('firefly_cart') === 'true';
      const fireflyMerge = cookiesUtils.getCookieValue('firefly_merge') === 'true';

      commit('setInitCart', {
        orderAttemptId,
        isFireFlyCart,
        fireflyMerge,
      });
    },
    setAttempts({ commit }, attempts) {
      commit('setAttempts', attempts);
    },
    handleErrorModal({ commit }) {
      commit('setErrorModal', {
        status: false,
        msg: '',
      });
    },
    showError({ dispatch }) {
      dispatch('toastMessageState/addMessage', this.$i18n.t('auth.info.need_login'), {
        root: true,
      });
    },
    getCart({ commit, dispatch, state }) {
      commit('setLoadStatus', CartUtils.STATUS.LOADING);
      commit('setLoading', true);
      const params = {};
      if (state.orderAttemptId) params.order_attempt_id = state.orderAttemptId;

      return Service.getCart(params)
        .then((response) => {
          if (response.balancer_error?.identifier) {
            errorMigrationBalancerFF(response.balancer_error.identifier, dispatch);
          }
          commit('setApiResponse', {
            type: 'get',
            response: response,
            error: null,
          });
          cartActions({ commit, dispatch }, response);
          const payload = JSON.parse(window.sessionStorage.getItem('msh_session_expired_payload'));
          if (payload?.line_items) {
            window.sessionStorage.removeItem('msh_session_expired_payload');
            dispatch('postCart', payload);
          }
        })
        .catch((err) => {
          commit('setApiResponse', {
            type: 'get',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error);
        })
        .finally(() => {
          commit('setLoadStatus', CartUtils.STATUS.LOADED);
          commit('setLoading', false);
        });
    },
    postCouponCode({ commit, dispatch, state }, payload) {
      commit('setLoading', true);
      return Service.postCart(payload)
        .then(async (response) => {
          await commit('setApiResponse', {
            type: 'post',
            response: response,
            error: null,
          });
          cartActions({ commit, dispatch }, response);
          return { status: true };
        })
        .catch(async (err) => {
          await commit('setApiResponse', {
            type: 'post',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error);
          return { status: false };
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    async postCart({ commit, dispatch, state, rootState, rootGetters }, payload) {
      commit('setLoading', true);
      commit('setStatus', 'Añadiendo...');

      if (!state.isFireflyCart) {
        await dispatch('sendPostalCodeAndCentreToCart', { payload, state });
      }

      const serviceAddToCart = state.isFireFlyCart ? Service.addToCart.bind(Service) : Service.postCart.bind(Service);
      const response = await serviceAddToCart({ ...payload, order_attempt_id: state.orderAttemptId })
        .then(async (response) => {
          await commit('setApiResponse', {
            type: 'post',
            response: response,
            error: null,
          });

          commit('setAttempts', 0);
          cartActions({ commit, dispatch }, response);

          // const shouldShowToast = !payload.is_from_checkout || payload.is_buy_later;
          const isApp = rootGetters['page/isApp'];
          if (isApp) sendAppEvents();

          const shouldShowToast =
            payload &&
            (payload.successAction === CART_ACTION_TYPES.TOAST ||
              payload.successAction === CART_ACTION_TYPES.TOAST_BASKET) &&
            !isApp;

          const shouldShowBasket =
            payload &&
            (payload.successAction === CART_ACTION_TYPES.BASKET ||
              payload.successAction === CART_ACTION_TYPES.TOAST_BASKET);

          if (shouldShowToast) {
            const hasMultipleLineItems =
              payload &&
              payload.line_items &&
              payload.line_items.filter((line_item) => {
                return !line_item.custom_service_data;
              }).length > 1;
            const multipleItemsTranslation = this.$i18n.t('cart.info_multiple_added_successfully');
            const uniqItemTranslation = this.$i18n.t('cart.info_added_successfully');

            // TODO: No deja utilizar mixins en VUEX, habr�a que refactorizar el mixin de navigationDetect y sacar su funcionalidad a un utils
            const isMobile = /Android|webOS|iPhone|iPad/i.test(window.navigator.userAgent);

            const canVibrate = isMobile && window && window.navigator && window.navigator.vibrate;
            if (canVibrate) window.navigator.vibrate(150);

            if (hasMultipleLineItems) {
              dispatch('toastMessageState/addMessage', multipleItemsTranslation, {
                root: true,
              });
            } else {
              dispatch('toastMessageState/addMessage', uniqItemTranslation, {
                root: true,
              });
            }
          }
          if (response.balancer_error?.identifier) {
            errorMigrationBalancerFF(response.balancer_error.identifier, dispatch);
          } else if (shouldShowBasket) {
            const shouldCloseCartModal = rootState.uiState[ICON_NAMES.MARKER] === false;
            dispatch(
              'uiState/showModal',
              {
                name: ICON_NAMES.MARKER,
                status: true,
              },
              { root: true },
            );

            if (shouldCloseCartModal) {
              setTimeout(() => {
                dispatch(
                  'uiState/showModal',
                  {
                    name: ICON_NAMES.MARKER,
                    status: false,
                  },
                  { root: true },
                );
              }, 4000);
            }
          }
          return {
            ok: true,
            body: response,
          };
        })
        .catch(async (err) => {
          await commit('setApiResponse', {
            type: 'post',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error, payload);
          return {
            ok: false,
          };
        })
        .finally(() => {
          commit('setStatus', '');
          commit('setLoading', false);
        });
      if (response.ok && state.isFireFlyCart) {
        await DatalayerService.createDataLayerOrderedItem(response.body.order_attempt_id, {
          payload: payload,
          cart: response.body,
        })
          .then(() => {
            commit('setLoading', true);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      }
      return response;
    },
    async sendPostalCodeAndCentreToCart({ commit }, data) {
      const setPostalCodePayload = {};
      const currentEciExpressValue = UtilsLocalStorage.getJSONItem('EciExpress');
      const currentClickAndCarValue = UtilsLocalStorage.getJSONItem('ClickNCar');

      if (data.payload?.line_items?.[0]?.shipping_method === 'eci_express') {
        if (
          !data.state.eciExpressValueInCart ||
          data.state.eciExpressValueInCart.postal_code !== currentEciExpressValue.postal_code
        ) {
          setPostalCodePayload.eci_express = {
            postal_code: currentEciExpressValue.postal_code,
          };
        }
      }
      if (data.payload?.line_items?.[0]?.shipping_method === 'click_and_car') {
        if (
          !data.state.clickAndCarValueInCart ||
          data.state.clickAndCarValueInCart.general_centre !== currentClickAndCarValue?.general_centre
        ) {
          setPostalCodePayload.click_and_car = {
            general_centre: currentClickAndCarValue.general_centre,
          };
        }
      }
      if (Object.keys(setPostalCodePayload).length) {
        await Service.postCart(setPostalCodePayload).then(() => {
          commit('setEciExpressInCart', currentEciExpressValue);
          commit('setClickAndCarInCart', currentClickAndCarValue);
        });
      }
    },
    updateCartItem({ commit, dispatch, state, rootState, rootGetters }, payload) {
      commit('setLoading', true);
      commit('setStatus', 'Actualizando...');

      const newUnitsQuantity = payload.action === 'PLUS' ? payload.item.units + 1 : payload.item.units - 1;
      const body = {
        order_attempt_id: state.orderAttemptId,
        ordered_item_id: payload.item.id,
        units: newUnitsQuantity,
      };

      return Service.updateCartItem(body)
        .then(async (response) => {
          await commit('setApiResponse', {
            type: 'post',
            response: response,
            error: null,
          });
          commit('setAttempts', 0);
          cartActions({ commit, dispatch }, response);

          // const shouldShowToast = !payload.is_from_checkout || payload.is_buy_later;
          const isApp = rootGetters['page/isApp'];
          if (isApp) sendAppEvents();

          const shouldShowToast =
            payload &&
            (payload.successAction === CART_ACTION_TYPES.TOAST ||
              payload.successAction === CART_ACTION_TYPES.TOAST_BASKET) &&
            !isApp;

          const shouldShowBasket =
            payload &&
            (payload.successAction === CART_ACTION_TYPES.BASKET ||
              payload.successAction === CART_ACTION_TYPES.TOAST_BASKET);

          if (shouldShowToast) {
            const hasMultipleLineItems =
              payload &&
              payload.line_items &&
              payload.line_items.filter((line_item) => {
                return !line_item.custom_service_data;
              }).length > 1;
            const multipleItemsTranslation = this.$i18n.t('cart.info_multiple_added_successfully');
            const uniqItemTranslation = this.$i18n.t('cart.info_added_successfully');

            // TODO: No deja utilizar mixins en VUEX, habr�a que refactorizar el mixin de navigationDetect y sacar su funcionalidad a un utils
            const isMobile = /Android|webOS|iPhone|iPad/i.test(window.navigator.userAgent);

            const canVibrate = isMobile && window && window.navigator && window.navigator.vibrate;
            if (canVibrate) window.navigator.vibrate(150);

            if (hasMultipleLineItems) {
              dispatch('toastMessageState/addMessage', multipleItemsTranslation, {
                root: true,
              });
            } else {
              dispatch('toastMessageState/addMessage', uniqItemTranslation, {
                root: true,
              });
            }
          }

          if (shouldShowBasket) {
            const shouldCloseCartModal = rootState.uiState[ICON_NAMES.MARKER] === false;
            dispatch(
              'uiState/showModal',
              {
                name: ICON_NAMES.MARKER,
                status: true,
              },
              { root: true },
            );

            if (shouldCloseCartModal) {
              setTimeout(() => {
                dispatch(
                  'uiState/showModal',
                  {
                    name: ICON_NAMES.MARKER,
                    status: false,
                  },
                  { root: true },
                );
              }, 1500);
            }
          }
          return true;
        })
        .catch(async (err) => {
          await commit('setApiResponse', {
            type: 'post',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error, payload);
        })
        .finally(() => {
          commit('setStatus', '');
          commit('setLoading', false);
        });
    },
    postCartVpr({ commit, dispatch, state, rootState, rootGetters }, payload) {
      commit('setLoading', true);
      commit('setStatus', 'Añadiendo...');
      const vprPostalCode = payload.vprPostalCode;
      const currentReference = payload.line_items?.[0]?.reference;
      let postVPRCartBindedFn;
      if (state.isFireFlyCart) {
        postVPRCartBindedFn = Service.addToCart.bind(Service, { ...payload, order_attempt_id: state.orderAttemptId });
      } else {
        delete payload.vprPostalCode;
        postVPRCartBindedFn = Service.postCart.bind(Service, { ...payload });
      }
      return postVPRCartBindedFn()
        .then(async (response) => {
          await commit('setApiResponse', {
            type: 'post',
            response: response,
            error: null,
          });

          commit('setAttempts', 0);
          cartActions({ commit, dispatch }, response);

          // const shouldShowToast = !payload.is_from_checkout || payload.is_buy_later;
          const isApp = rootGetters['page/isApp'];
          if (isApp) sendAppEvents();

          const shouldShowToast =
            payload &&
            (payload.successAction === CART_ACTION_TYPES.TOAST ||
              payload.successAction === CART_ACTION_TYPES.TOAST_BASKET) &&
            !isApp;

          const shouldShowBasket =
            payload &&
            (payload.successAction === CART_ACTION_TYPES.BASKET ||
              payload.successAction === CART_ACTION_TYPES.TOAST_BASKET);

          if (shouldShowToast) {
            const hasMultipleLineItems =
              payload &&
              payload.line_items &&
              payload.line_items.filter((line_item) => {
                return !line_item.custom_service_data;
              }).length > 1;
            const multipleItemsTranslation = this.$i18n.t('cart.info_multiple_added_successfully');
            const uniqItemTranslation = this.$i18n.t('cart.info_added_successfully');

            // TODO: No deja utilizar mixins en VUEX, habr�a que refactorizar el mixin de navigationDetect y sacar su funcionalidad a un utils
            const isMobile = /Android|webOS|iPhone|iPad/i.test(window.navigator.userAgent);

            const canVibrate = isMobile && window && window.navigator && window.navigator.vibrate;
            if (canVibrate) window.navigator.vibrate(150);

            if (hasMultipleLineItems) {
              dispatch('toastMessageState/addMessage', multipleItemsTranslation, {
                root: true,
              });
            } else {
              dispatch('toastMessageState/addMessage', uniqItemTranslation, {
                root: true,
              });
            }
          }

          if (shouldShowBasket) {
            const shouldCloseCartModal = rootState.uiState[ICON_NAMES.MARKER] === false;
            dispatch(
              'uiState/showModal',
              {
                name: ICON_NAMES.MARKER,
                status: true,
              },
              { root: true },
            );

            if (shouldCloseCartModal) {
              setTimeout(() => {
                dispatch(
                  'uiState/showModal',
                  {
                    name: ICON_NAMES.MARKER,
                    status: false,
                  },
                  { root: true },
                );
              }, 1500);
            }
          }
          const currentItem = response.line_items.find((item) => item.reference === currentReference);
          if (!currentItem) {
            return true;
          }
          if (!state.isFireFlyCart) {
            const shippingMethods = [];
            const currentItemShippingMethod = {
              commerce_items: [currentItem.id],
              shipping_method: 'vpr_urgent',
            };
            shippingMethods.push(currentItemShippingMethod);
            const vprShippingPayload = {
              line_items: [{ commerce_item: currentItem.id }],
              shipping_methods: shippingMethods,
              urgent_vpr: {
                postal_code: vprPostalCode,
              },
            };
            await Service.updateCartShippingMethod(vprShippingPayload);
          }
          return true;
        })
        .catch(async (err) => {
          await commit('setApiResponse', {
            type: 'post',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error, payload);
        })
        .finally(() => {
          commit('setStatus', '');
          commit('setLoading', false);
        });
    },
    processToCheckout({ commit, dispatch, state }, payload) {
      commit('setLoading', true);
      return Service.processToCheckout(payload)
        .then(async (response) => {
          await commit('setApiResponse', {
            type: 'post',
            response: response,
            error: null,
          });
          const { _redirect_to } = response;
          window.location.href = _redirect_to;
        })
        .catch(async (err) => {
          await commit('setApiResponse', {
            type: 'post',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error);
        })
        .finally(() => {
          commit('setStatus', '');
          commit('setLoading', false);
        });
    },
    putCart({ commit, dispatch, state }, payload) {
      commit('setLoading', true);
      Service.putCart(payload)
        .then((response) => {
          // commit('setApiResponse', {
          //   type: 'post',
          //   response: response,
          //   error: null
          // });
          cartActions({ commit, dispatch }, response);
        })
        .catch((err) => {
          // commit('setApiResponse', {
          //   type: 'post',
          //   response: null,
          //   error: err
          // });
          const error = err[1];
          errorActions(commit, state, error);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    async deleteItemByCart({ commit, dispatch, state }, payload) {
      commit('setLoading', true);
      commit('setStatus', 'Eliminando...');
      const serviceDeleteItems = state.isFireFlyCart
        ? Service.deleteCartItem.bind(Service)
        : Service.deleteCartItemOmns.bind(Service);
      const response = await serviceDeleteItems({ ...payload, order_attempt_id: state.orderAttemptId })
        .then((response) => {
          commit('setApiResponse', {
            type: 'delete',
            response: response,
            error: null,
          });
          cartActions({ commit, dispatch }, response);
          return {
            ok: true,
            body: response,
          };
        })
        .catch((err) => {
          commit('setApiResponse', {
            type: 'delete',
            response: null,
            error: err[1],
          });
          const error = err[1];
          errorActions({ commit, dispatch }, state, error);
          return {
            ok: false,
          };
        })
        .finally(() => {
          commit('setStatus', '');
          commit('setLoading', false);
        });
      if (response.ok && state.isFireFlyCart) {
        const ordered_item_id = payload?.line_items[0]?.id;
        if (!ordered_item_id) return;
        await DatalayerService.deleteDataLayerOrderedItem(response.body.order_attempt_id, ordered_item_id)
          .then(() => {
            commit('setLoading', true);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      }
    },
    getTimeSlot({ commit, state }) {
      commit('setLoading', true);
      const params = {
        order_attempt_id: state.orderAttemptId,
        order_delivery_id: state.eciExpress?.order_delivery_identifier,
      };
      Service.getTimeSlots(params)
        .then((result) => {
          commit('setEciExpressTimeSlots', result.days);
        })
        .catch((err) => {
          console.error('getTimeSlot ~ err', err);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    getTimeSlotClicknCar({ commit, state }) {
      commit('setLoading', true);

      const params = {
        order_attempt_id: state.orderAttemptId,
        order_delivery_id: state.clicAndCar?.order_delivery_identifier,
      };

      const getTimeSlotsBinded = state.isFireFlyCart
        ? Service.getTimeSlots.bind(Service)
        : Service.getTimeSlotsClicknCar.bind(Service);

      getTimeSlotsBinded(params)
        .then((result) => {
          commit('setClicknCarTimeSlots', result.days);
        })
        .catch((err) => {
          console.error('getTimeSlotClicknCar ~ err', err);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    showExpiredCartError({ commit }, payload) {
      commit('setErrorExpiredCart', {
        status: payload.status,
        msg: payload.msg,
      });
    },
    showEciCardError({ commit }, payload) {
      commit('setErrorEciCard', {
        status: payload.status,
        msg: payload.msg,
      });
    },
    showErrorToast({ dispatch }, payload) {
      const payloadError =
        '<div class="toast-icon-wrapper"><i class="toast-error"></i></div>' +
        '<div>' +
        this.$i18n.t(payload.msg) +
        '</div>';

      dispatch('toastMessageState/addMessage', payloadError, {
        root: true,
      });
    },
    setEciExpress({ commit }, payload) {
      commit('setEciExpress', payload);
    },
    setOrderAttemptId({ commit }, payload) {
      commit('setOrderAttemptId', payload);
    },
    setClickNCar({ commit }, payload) {
      commit('setclicAndCar', payload);
    },
    resetClickCarTimeSlots({ commit }) {
      commit('setClicknCarTimeSlots', []);
    },
    resetEciExpressTimeSlots({ commit }) {
      commit('setEciExpressTimeSlots', []);
    },
    setCenterDeliveryType({ commit }, payload) {
      commit('setCenterDeliveryType', payload);
    },
    setCurrentDeliveryType({ commit }, payload) {
      commit('setCurrentDeliveryType', payload);
    },
    setUserDemographics({ commit }, payload) {
      commit('setUserDemographics', payload);
    },
  },
  getters: {
    getOrderAttemptId(state) {
      return state.orderAttemptId;
    },
    getIsFireFlyCart(state) {
      return state.isFireFlyCart;
    },
    getAttempts(state) {
      return state.attempts;
    },
    getIsCSC(state) {
      return state.isCSC;
    },
    getRedirectUrlBasket(state) {
      return state.redirectUrlBasket;
    },
    getLoadingStatus(state) {
      return state.isLoading;
    },
    getZip(state) {
      if (state.zip) return state.zip;
      if (state.isLogged) return state.preferred_zip;
      return '';
    },
    getCenterDeliveryType(state) {
      return state.centerDeliveryType;
    },
    getCurrentDeliveryType(state) {
      return state.currentDeliveryType;
    },
    getEciCard(state) {
      return state.eci_card;
    },
    getStatus(state) {
      return state.status;
    },
    getTotalItems(state) {
      return state.total_items;
    },
    getFullCartData(state) {
      return {
        items: state.items,
        summary: state.summary,
        order_discounts: state.order_discounts,
        additional_data: state.additional_data,
        gift_receipt_enabled: state.gift_receipt_enabled,
        shipping_prime_activated: state.shipping_prime_activated,
        shipping_prime_infos: state.shipping_prime_infos ? state.shipping_prime_infos : null,
        checkout_url: state.checkout_url,
      };
    },
    getCartLineItems(state) {
      return state.items;
    },
    getShippingPrimeActivated(state) {
      return state.shipping_prime_activated;
    },
    isLogged(state) {
      return state.isLogged;
    },
    getApiResponse(state) {
      return state.apiResponse;
    },
    getGuestEmail(state) {
      return state.guest_email;
    },
    getUser(state) {
      return state.user;
    },
    getEciExpress(state) {
      return state.eciExpress;
    },
    getClicAndCar(state) {
      return state.clicAndCar;
    },
    getEciExpressTimeSlots(state) {
      return state.eciExpressTimeSlots;
    },
    getClickNCarTimeSlots(state) {
      return state.clickNcarTimeSlots;
    },
    getErrorMsg(state) {
      return state.errorModalMsg;
    },
    isModalErrorOpen(state) {
      return {
        status: state.showErrorModal,
        msg: state.errorModalMsg,
        param: state.errorModalParam,
      };
    },
    isEciCardError(state) {
      return { status: state.showEciCardError, msg: state.errorEciCardMsg };
    },
    hasCouponError(state) {
      return { status: state.showCouponError, msg: state.errorCouponMsg };
    },
    isExpiredCart(state) {
      return {
        status: state.showErrorExpiredCart,
        msg: state.errorExpiredCartMsg,
      };
    },
    getLoyaltyData(state) {
      return state.loyalty;
    },
    showLoyaltyPoints(state) {
      return state.hasLoyaltyPoints;
    },
    getCartCallStatus(state) {
      return state.loadStatus;
    },
    getCartError(state) {
      if (!state.apiResponse.error) return {};
      return state.apiResponse.error;
    },
    getUserDemographics(state) {
      return state.userDemographics;
    },
    getUserGender(state) {
      if (!state.userDemographics) return 'desconocido';
      if (!state.userDemographics.NA_SEXO) return 'desconocido';
      return state.userDemographics.NA_SEXO.toLowerCase();
    },
    getAlgoliaUserData(state, getters, rootState, rootGetters) {
      return {
        algoliaUserToken: 'anonymous-' + rootGetters['page/getSessionId'],
        algoliaAuthenticatedUserToken: state.algoliaAuthenticatedUserToken,
      };
    },
    itemsInCart(state) {
      return state.items && Array.isArray(state.items) ? state.items.filter((item) => !UtilsCart.isService(item) && !UtilsCart.isInsuranceGeneral(item))
      .sort((itemOne, itemTwo)=>{
        if(itemOne.order_item_sequence<itemTwo.order_item_sequence) return -1;
        if(itemOne.order_item_sequence>itemTwo.order_item_sequence) return 1;
        return 0;
      })
      : [];
    }
  },
});

export default cart;
