import Validators from 'CommonUtils/validator';
import API from 'Services/Newsletter';
const Service = new API('/api/vuestore');

const MESSAGES = Object.freeze({
  EMAIL_ERROR: 'Por favor, verifique el email ingresado.',
  PRIVACY_ERROR: 'Debe aceptar las políticas de privacidad y condiciones de subscripción.',
  POST_ERROR: 'No ha sido posible tramitar la subscripción.',
  SUBSCRIBED_ERROR: 'Gracias por tu interés en nuestra newsletter. Revisa tu email para completar tu proceso de suscripción.',
});

function checkError(commit, getters) {
  if (!Validators.isValidEmail(getters.getEmail)) {
    commit('setEmailError', MESSAGES.EMAIL_ERROR);
    commit('setError', MESSAGES.EMAIL_ERROR);
    return;
  }
  commit('setError', '');
  commit('setEmailError', '');
  commit('setPrivacyError', '');
}

const newsletter = () => ({
  namespaced: true,
  state: {
    code: '',
    privacy_link: '',
    designers: false,
    email: '',
    error: '',
    privacyError: '',
    emailError: '',
    subscribed: [],
    message: '',
    privacyChecked: false,
    blurred: false,
    isLoading: false,
  },
  mutations: {
    initNewsletter(state, { code, privacy_link, designers }) {
      state.code = code;
      state.privacy_link = privacy_link;
      state.designers = designers;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setPrivacyError(state, payload) {
      state.privacyError = payload;
    },
    setEmailError(state, payload) {
      state.emailError = payload;
    },
    setSubscribed(state, payload) {
      state.subscribed = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setPrivacyChecked(state, payload) {
      state.privacyChecked = payload;
    },
    setBlurred(state, payload) {
      state.blurred = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async initNewsletter({ commit }, { code, privacy_link }) {
      await commit('initNewsletter', {
        code,
        privacy_link,
      });
    },
    updateEmail({ commit, getters }, payload) {
      commit('setEmail', payload);
      checkError(commit, getters);
    },
    updatePrivacyChecked({ commit, getters }, payload) {
      commit('setPrivacyChecked', payload);
      if (getters.isPrivacyChecked) checkError(commit, getters);
    },
    togglePrivacyChecked({ commit, getters }) {
      commit('setPrivacyChecked', !getters.isPrivacyChecked);
      if (getters.isPrivacyChecked) checkError(commit, getters);
    },
    updateBlurred({ commit }, payload) {
      commit('setBlurred', payload);
    },
    postNewsletterSub({ commit, getters }) {
      // Validaciones
      if (getters.getSubscribed.includes(getters.getEmail)) return commit('setMessage', MESSAGES.SUBSCRIBED_ERROR);
      if (getters.getError) return;
      if (!getters.isPrivacyChecked) {
        commit('setPrivacyError', MESSAGES.PRIVACY_ERROR);
        commit('setError', MESSAGES.PRIVACY_ERROR);
        return;
      }
      // Request body
      const options = {
        code: getters.getCode,
        email: getters.getEmail,
      };
      commit('setIsLoading', true);
      Service.postNewsletterSub(options)
        .then((response) => {
          commit('setSubscribed', [...getters.getSubscribed, getters.getEmail]);
          commit('setMessage', response.message);
        })
        .catch((err) => {
          const errMessage = MESSAGES.POST_ERROR;
          commit('setMessage', errMessage);
          commit('setError', errMessage);
          console.log(err);
        })
        .finally(() => commit('setIsLoading', false));
    },
    clearMessage({ commit }) {
      commit('setMessage', '');
    },
    postNewsletterDesignersSub({ commit, getters }, email) {
      const options = { code: getters.getCode, email };

      commit('setIsLoading', true);
      commit('setMessage', '');

      Service.postNewsletterSub(options)
        .then((response) => commit('setMessage', response.message))
        .catch(() => commit('setMessage', MESSAGES.POST_ERROR))
        .finally(() => commit('setIsLoading', false));
    },
    clearNewsletterDesignersResponse({ commit }) {
      commit('setIsLoading', false);
      commit('setMessage', '');
    },
  },
  getters: {
    getCode(state) {
      return state.code;
    },
    getPrivacyLink(state) {
      return state.privacy_link;
    },
    getEmail(state) {
      return state.email;
    },
    getError(state) {
      return state.error;
    },
    getPrivacyError(state) {
      return state.privacyError;
    },
    getEmailError(state) {
      return state.emailError;
    },
    getMessage(state) {
      return state.message;
    },
    getSubscribed(state) {
      return state.subscribed;
    },
    isPrivacyChecked(state) {
      return state.privacyChecked;
    },
    isBlurred(state) {
      return state.blurred;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
});
export default newsletter;
