import API from 'Services/Wishlist';
import cookiesUtils from 'CommonUtils/cookies.js';

const Service = new API('/api/vuestore');

const getWishListByCookie = () => {
  let xtraLists = [];
  let json = cookiesUtils.getCookieValue('xtra_wishlists');
  if (json && json != -1) xtraLists = JSON.parse(json);
  return xtraLists;
};
const addNewWishListByCookie = (id, name) => {
  window.localStorage.setItem('WL_' + id, []);

  let xtraLists = getWishListByCookie();
  xtraLists.push({ id: id, name: name, length: 0 });
  let json_str = JSON.stringify(xtraLists);
  cookiesUtils.setCookie('xtra_wishlists', json_str);
};
const getProductFromWishListIdCookie = listId => {
  return window.localStorage.getItem('WL_' + listId).split(',');
};
const addProductToWishListByCookie = (productId, listId) => {
  const storage = window.localStorage.getItem('WL_' + listId);
  let productsList = storage ? storage.split(',') : [];
  productsList.push(productId);

  updateListProductLengthByCookie(listId, productsList.length);

  window.localStorage.setItem('WL_' + listId, productsList.join(','));
};

const removeProductFromWishListByCookie = (productId, listId) => {
  let productsList = window.localStorage.getItem('WL_' + listId).split(',');
  productsList = productsList.filter(id => id !== productId);

  updateListProductLengthByCookie(listId, productsList.length);

  window.localStorage.setItem('WL_' + listId, productsList.join(','));
};

const updateListProductLengthByCookie = (listId, productLength) => {
  let xtraLists = getWishListByCookie();
  xtraLists.find(list => list.id === listId).length = productLength;
  let json_str = JSON.stringify(xtraLists);
  cookiesUtils.setCookie('xtra_wishlists', json_str);
};

const havLists = () => {
  return cookiesUtils.existsCookie('xtra_wishlists');
};

const removeCookies = () => {
  cookiesUtils.setCookie('xtra_wishlists', null, -1);
};

const setCookiesAndStorage = wishLists => {
  const xtraLists = wishLists.map(item => ({
    id: item._id,
    name: item.list_name,
    length: item.products.length
  }));
  let json_str = JSON.stringify(xtraLists);
  cookiesUtils.setCookie('xtra_wishlists', json_str);
  wishLists.forEach(wishList => {
    window.localStorage.setItem(
      'WL_' + wishList._id,
      wishList.products.map(product => product.product_id).join(',')
    );
  });
};

const wishlist = () => ({
  namespaced: true,
  state: {
    isLoading: false,
    wishList: {}
  },
  mutations: {
    initWishlist(state, wishLists) {
      if (wishLists) setCookiesAndStorage(wishLists);
      state.wishList = Object.assign(
        {},
        ...getWishListByCookie().map(list => ({
          [list.id]: {
            name: list.name,
            products: getProductFromWishListIdCookie(list.id)
          }
        }))
      );
    },
    removeWishlist(state) {
      removeCookies();
    },
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    createNewWishList(state, { id, name }) {
      addNewWishListByCookie(id, name);
      state.wishList[id] = { name, products: [] };
    },
    addProductToWishList(state, { productId, listId }) {
      addProductToWishListByCookie(productId, listId);
      state.wishList[listId].products.push(productId);
    },
    removeProductFromWishList(state, { productId, listId }) {
      removeProductFromWishListByCookie(productId, listId);
      state.wishList[listId].products = state.wishList[listId].products.filter(
        id => id !== productId
      );
    }
  },
  actions: {
    async initWishlist({ commit, dispatch }) {
      let wishLists = null;
      if (!havLists()) {
        wishLists = await dispatch('getWishList');
      }
      commit('initWishlist', wishLists);
    },
    getWishList({ commit }) {
      commit('isLoading', true);
      return Service.getWishList().finally(() => commit('isLoading', false));
    },
    createNewWishList({ commit }, name) {
      commit('isLoading', true);
      return Service.createNewWishList(name)
        .then(res => {
          const id = res.ops[0]._id;
          commit('createNewWishList', { id, name });
        })
        .finally(() => {
          commit('isLoading', false);
        });
    },
    updateProductIntoWishList(
      { commit },
      { productId, productName, referenceId, listId, variants, extraInfo, isActionDelete }
    ) {
      commit('isLoading', true);
      return Service.updateProductIntoWishList(
        productId,
        productName,
        referenceId,
        listId,
        variants,
        extraInfo,
        isActionDelete
      )
        .then(res => {
          if (!isActionDelete) {
            commit('addProductToWishList', { productId, listId });
          } else {
            commit('removeProductFromWishList', { productId, listId });
          }
        })
        .finally(() => {
          commit('isLoading', false);
        });
    }
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
    getWishlist(state) {
      return state.wishList;
    },
    getAllWishedProductsSet(state) {
      return new Set(
        Object.values(state.wishList).reduce(
          (products, list) => products.concat(list.products),
          []
        )
      );
    }
  }
});

export default wishlist;
