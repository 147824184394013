/**
 * Enum for place types.
 * @readonly
 * @enum name of place
 */
const ICON_NAMES = Object.freeze({
  SEARCH: 'modalSearch',
  USER: 'modalUser',
  CART: 'modalCart',
  FILTER: 'modalFilter',
  FILTER_SEARCH: 'modalFilterSearch',
  FILTER_FACET:'modalFilterFacet',
  FILTER_FACET_TAG:'modalFilterFacetTag',
  SORT:'modalSort',
  SEARCH_SPEECH: 'searchSpeech',
  MARKER:'modalMarket',
  CATEGORIES: 'modalCategories',
});

export default ICON_NAMES;
