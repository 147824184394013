module.exports = {
  existsCookie: name => {
    return document.cookie.match(
      '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
    );
  },
  getCookieValue: name => {
    const result = document.cookie.match(
      '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
    );
    return result ? result.pop() : '';
  },
  setCookie: function(name, value, days, domain, path, secure) {
    var cookie = [name + '=' + value];
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      cookie.push('expires=' + date.toGMTString());
    }
    if (domain) cookie.push('domain=' + domain);
    cookie.push('path=' + (path || '/'));
    if (secure) cookie.push('secure');
    document.cookie = cookie.join(';');
  },
  deleteCookie: function (name){
    this.setCookie(name, null, -1);
  },
  getCookie: function(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  getCookieByName: function (cookieName) {
    const allCookies = document.cookie;
    const cookiesArray = allCookies.split('; ');
    const targetCookie = cookiesArray.find(cookie => cookie.startsWith(cookieName));
    return targetCookie?.split('=')[1]
}
};
