import ServiceAPI from 'Services/ServiceAPI';

class Wishlist extends ServiceAPI {
  constructor(endpoint) {
    super(endpoint);
  }

  createNewWishList(name) {
    const service = '/wishlist/lists';
    const body = {
      list_name: name,
      is_private: 'false',
      is_commentable: 'false'
    };
    return this.post(body, service);
  }

  updateProductIntoWishList(
    productId,
    productName,
    referenceId,
    listId,
    variants,
    extraInfo,
    isActionDelete
  ) {
    const service = `/wishlist/products${isActionDelete ? '/' + productId : ''}`;
    const body = {
      product_id: productId,
      product_name: productName,
      reference_id: referenceId,
      list_id: listId,
      product_variants: variants,
      extra_info: extraInfo,
      delete_product: isActionDelete
    };
    return this.post(body, service);
  }
  getWishList() {
    const service = '/wishlist/lists';
    return this.get(service);
  }
}

export default Wishlist;
