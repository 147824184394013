/**
  Logic to generate component uuid's sequentially as instances that uses them are rendered. Each uuid generated is
  used as the seed for the next uuid. Due to the fact that the first seed is a constant, the same seeds
  are generated in SSR and client hydration, and corresponds to the same component instances because the
  render sequence is the same in server and client.

  It's important to use the mutations (sync) instead of actions (async) in order to have the uuid in the
  'created' hook, so it can be used by the component or child components before they are mounted, avoiding
  mismatch warnings.

  Must call initUuid at page level (currently it's called in the Layout.vue component) because on server
  the seed won't reset on each request, causing mismatch warnings on page reload;

  Usage in components with Composition API:
    import useUUID from 'CommonComposables/useUUID';
    setup() {
      const { getSequentialUuid } = useUUID();
      const uuid = ref(getSequentialUuid());
    }
  Usage in components with Options API:
    import { mapMutations, mapGetters } from 'vuex';
    data() {
      return {
        uuid: null,
      }
    },
    methods: {
      ...mapGetters('uuid', ['getCurrentSeed']),
      ...mapMutations('uuid', ['getSequentialUuid']),
    },
    created() {
      this.getSequentialUuid();
      this.uuid = this.getCurrentSeed();
    }
 */

  import uuidv3 from 'uuid/v3';
  const uuidNamespace = '4d790ea9-0c43-4032-bd92-1ed46ba125dc';
  const initialSeed = '74307108-5b2b-4478-8b23-9d7fa597191d';

  const uuid = () => ({
    namespaced: true,
    state: {
      seed: initialSeed,
    },
    mutations: {
      initUuid(state, seed) {
        state.seed = seed ? seed : initialSeed;
      },
      getSequentialUuid(state) {
        state.seed = uuidv3(uuidNamespace, state.seed);
      },
    },
    actions: {
      async initUuid({ commit }, seed) {
        await commit('initUuid', seed);
      },
    },
    getters: {
      getCurrentSeed(state) {
        return state.seed;
      }
    }
  });

  export default uuid;