import axios from 'axios';

const abortableRequests = {
  '/new-search': null,
  '/search': null,
  '/products_list': null
};

const handle = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => {
      return Promise.reject([undefined, error.message === 'canceled' ? error.message : error.response]);
    });
};

class ServiceAPI {
  constructor(baseURL, endpointHasFullDomain = false) {
    let base = baseURL;
    if (!import.meta.env.SSR && !endpointHasFullDomain) {
      const api = window.__MOONSHINE_STATE__?._api_base;
      base = api;
    }
    this.baseURL = base;
  }
  async get(service, params, headers) {
    const baseService = service.match(/(\/[^/]*)/)[0];
    const controller = new AbortController();

    abortableRequests[baseService]?.abort();
    if (abortableRequests[baseService] !== undefined) {
      abortableRequests[baseService] = controller;
    }

    const url = this.baseURL + service;
    const [response, error] = await handle(axios.get(url, { params, headers, signal: controller.signal }));
    if (error || !response) return { error };
    const data = response.data;
    Object.assign(data, { headers: response.headers });
    return data;
  }
  async post(body, service, params, headers) {
    const url = this.baseURL + service;
    const [response, error] = await handle(axios.post(url, body, { params, headers }));
    if (error || !response) return { error };
    const data = response.data;
    return data;
  }
  async put(body, service, params, headers) {
    const url = this.baseURL + service;
    const [response, error] = await handle(axios.put(url, body, { params, headers }));
    if (error || !response) return { error };
    const data = response.data;
    return data;
  }
  async patch(body, service, params, headers) {
    const url = this.baseURL + service;
    const [response, error] = await handle(axios.patch(url, body, { params, headers }));
    if (error || !response) return { error };
    const data = response.data;
    return data;
  }
  async delete(service, params, headers) {
    const url = this.baseURL + service;
    const [response, error] = await handle(axios.delete(url, { params, headers }));
    if (error || !response) return { error };
    const data = response.data;
    return data;
  }
  postCancellable(body, service) {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = this.baseURL + service;
    return { axiosPost: axios.post(url, body, { signal }), controller };
  }
}

export default ServiceAPI;
