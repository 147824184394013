
const analytics = () => ({
  namespaced: true,
  state: {
    indexPushed: {
      productModuleVue: 0,
      productModuleWpc: 0,
      productModuleEci: 0,
      productWishlist: 0,
      productMultipleAddToCart: 0,
    }
  },
  mutations: {
    setMultipleAddToCartIndexPushed(state, value) {
      state.multipleAddToCartIndexPushed = value;
    },
    setIndexPushed(state, {type, index}) {
      state.indexPushed[type] = index;
    }
  },  
  actions: {
    /**
     * Increments or sets the value of target pushed index type
     * @param {Object} context
     * @param {Object} payload
     * @param {string} payload.type - Target state.indexPushed key
     * @param {string} payload.increment - Value to be added to the current state.indexpushed[type] value
     * @param {string} payload.total - Value to be set as current state.indexpushed[type] value. If present, increment param will be ignored
     */
    incrementIndexPushed({ commit, getters }, {type, increment, total}) {
      const indexIncrement = increment || 1;
      const index = total ?
        total :
        getters.getIndexPushed(type) + indexIncrement;

      commit('setIndexPushed', { type, index});
    }
  },
  getters: {
    getIndexPushed: (state) => (type) => {
      return state.indexPushed[type];
    },
  }
});

export default analytics;
