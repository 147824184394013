const SLASH_SEPARATOR = '/';
const FILTER_SEPARATOR = '::';
const SPLIT_SEPARATOR = ',,';

const _fromMapperToFilters = (mapper) => {
  return Object.keys(mapper).map((key) => {
    const values = [...mapper[key]].join(SPLIT_SEPARATOR);
    return `${key}${FILTER_SEPARATOR}${values}`;
  });
};

const _getFiltersList = (filters) => {
  const isList = Array.isArray(filters);
  const isString = typeof filters === 'string';
  if (isList) return filters;
  if (isString && filters.includes(SLASH_SEPARATOR)) {
    return filters.split(SLASH_SEPARATOR);
  }
};

const _filterMapper = (filters, mapper) => {
  _getFiltersList(filters).forEach((filter) => {
    const [key, value] = filter.split(FILTER_SEPARATOR);
    const values = value.split(SPLIT_SEPARATOR);
    if (mapper.hasOwnProperty(key)) {
      values.forEach((value) => mapper[key].add(value));
    }
    mapper[key] = new Set(values);
  });
};

const merge = (previous, current) => {
  const mapper = {};
  if (previous) _filterMapper(previous, mapper);
  if (current) _filterMapper(current, mapper);
  const filters = _fromMapperToFilters(mapper);
  return filters;
};

const getPathFromList = (trail) => {
  return (trail || []).join(SLASH_SEPARATOR) || null;
};

module.exports = {
  merge,
  getPathFromList,
};
