/**
 * Listado de expresiones regulares
 * 
 * NOTE: https://regex101.com/
 */
const REG_EX = Object.freeze({
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  TRANSITION: /(initial|\s*-?[0-9]*\.?[0-9]*m?s?)\s+(initial|linear|ease(?:-in|-out|-in-out)?|cubic-bezier\((?:\s*-?[0-9]*?\.?[0-9]*?\s*,?)*?\))?(initial|\s*-?[0-9]*\.?[0-9]*m?s?)/,
  PIECE_TRANSITION: /(left|top|opacity|height|width|transform|-webkit-transform)\s+(initial|\s*-?[0-9]*\.?[0-9]*m?s?)\s+(initial|linear|ease(?:-in|-out|-in-out)?|cubic-bezier\((?:\s*-?[0-9]*?\.?[0-9]*?\s*,?)*?\))?(initial|\s*-?[0-9]*\.?[0-9]*m?s?)/,
  OBJECT_FIT: /^(fill|contain|cover|none|scale-down)$/
});


/**
 * Validar email
 * @param {String} email
 */
const isValidEmail = email => REG_EX.EMAIL.test(String(email));

/**
 * Validar transición. Por ejemplo: '0.2s ease-out'
 * @param {String} transition
 */
const isValidTransition = transition => REG_EX.TRANSITION.test(String(transition));

/**
 * Validar transición en una pieza. Por ejemplo: 'left 0.2s ease-out'
 * @param {String} transition
 */
const isValidPieceTransition = transition => REG_EX.PIECE_TRANSITION.test(String(transition));

/**
 * Validar opciones de la propiedad object-fit. Por ejemplo: 'cover'
 * @param {String} value
 */
const isValidObjectFit = value => REG_EX.OBJECT_FIT.test(String(value));




export default {
  isValidEmail,
  isValidTransition,
  isValidPieceTransition,
  isValidObjectFit
};