import API from 'Services/BuyLater';
import operations from "CommonUtils/operations/uniqBy";
const Service = new API('/api/buylater/');

const buylater = () => ({
  namespaced: true,
  state: {
    products: [],
    total: 0,
    isLoading: false
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload.products;
      state.total = payload.total;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    resetState(state) {
      state.products = [];
      state.total = 0;
    },
    removeProductInState(state, sku) {
      state.products = state.products.filter(product => {
        return sku !== product.selected_sku_buy_later;
      });
      state.total = state.total === 1 ? 1 : (state.total = state.total - 1);
    }
  },
  actions: {
    async resetState({ commit }) {
      commit('resetState');
    },
    async getBuyLaterProducts({ commit, state }, payload) {
      commit('setLoading', true);
      await Service.getBuyLater(payload)
        .then(response => {
          commit('setLoading', false);
          const { success, data, total } = response;

          if (!success) return;

          const productsData = state.products;
          let newProductsArray;

          if (payload.currentPage === 1) {
            newProductsArray = data;
          } else {
            newProductsArray = operations.uniqBy(productsData.concat(data), 'selected_sku_buy_later');
          }
          return commit('setProducts', { products: newProductsArray, total });
        })
        .catch(() => {
          commit('setLoading', false);
        });
    },
    async addBuyLater({ commit, state }, payload) {
      commit('setLoading', true);
      const { product_id, reference: sku, title: product_name } = payload;
      await Service.addBuyLater(product_id, sku, product_name)
        .then(() => {
          commit('setProducts', {
            products: state.products,
            total: state.total + 1
          });
        })
        .catch(err => console.error(err));
      commit('setLoading', false);
    },
    async deleteBuyLater({ commit }, payload) {
      commit('setLoading', true);
      const { reference: sku } = payload;
      await Service.deleteBuyLater(sku);
      await commit('removeProductInState', sku);
      commit('setLoading', false);
    }
  },
  getters: {
    getBuyLater(state) {
      return state.products;
    },
    getTotalCount(state) {
      return state.total;
    },
    getLoadingBuyLater(state) {
      return state.isLoading;
    }
  }
});

export default buylater;
