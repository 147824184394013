/**
 * Enum for cart action types.
 * @readonly
 * @enum type of action
 */
const CART_ACTION_TYPES = Object.freeze({
  NONE: 'none',
  TOAST: 'toast',
  BASKET: 'basket',
  TOAST_BASKET: 'toast-basket',
});

export default CART_ACTION_TYPES;