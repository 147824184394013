/**
 * Enum for grid types.
 * @readonly
 * @enum name of grid
 */
const GRID_TYPES = Object.freeze({
  BIG_PRODUCTS: 'big_products',
  LITTLE_PRODUCTS: 'little_products'
});

export default GRID_TYPES;
