const layout = () => ({
  namespaced: true,
  state: {
    userScroll: false,
    scrollPosition: {
      x: 0,
      y: 0,
    },
  },
  mutations: {
    setUserScroll(state, payload) {
      state.userScroll = payload;
    },
    setPagePosition(state, payload) {
      state.scrollPosition.x = payload.x;
      state.scrollPosition.y = payload.y;
    },
  },
  actions: {
    dispatchUserScroll({ commit }) {
      commit('setUserScroll', true);
    },
    dispatchVMScroll({ commit }) {
      commit('setUserScroll', false);
    },
    setPageScrollPosition({ commit }, payload) {
      commit('setPagePosition', payload);
    },
  },
  getters: {
    getUserScroll(state) {
      return state.userScroll;
    },
    getPageScrollPosition(state) {
      return state.scrollPosition;
    },
  },
});

export default layout;
